export const list = {
  Date: [
    "6/6/2020",
    "6/7/2020",
    "6/8/2020",
    "6/9/2020",
    "6/10/2020",
    "6/11/2020",
    "6/12/2020",
    "6/13/2020",
    "6/14/2020",
    "6/15/2020",
    "6/16/2020",
    "6/17/2020",
    "6/18/2020",
    "6/19/2020",
    "6/20/2020",
    "6/21/2020",
    "6/22/2020",
    "6/23/2020",
    "6/24/2020",
    "6/25/2020",
    "6/26/2020",
    "6/27/2020",
    "6/28/2020",
    "6/29/2020",
    "6/30/2020",
    "7/1/2020",
    "7/2/2020",
    "7/3/2020",
    "7/4/2020",
    "7/5/2020",
    "7/6/2020",
    "7/7/2020",
    "7/8/2020",
    "7/9/2020",
    "7/10/2020",
    "7/11/2020",
    "7/12/2020",
    "7/13/2020",
    "7/14/2020",
    "7/15/2020",
    "7/16/2020",
    "7/17/2020",
    "7/18/2020",
    "7/19/2020",
    "7/20/2020",
    "7/21/2020",
    "7/22/2020",
    "7/23/2020",
    "7/24/2020",
    "7/25/2020",
    "7/26/2020",
    "7/27/2020",
    "7/28/2020",
    "7/29/2020",
    "7/30/2020",
    "7/31/2020",
    "8/1/2020",
    "8/2/2020",
    "8/3/2020",
    "8/4/2020",
    "8/5/2020",
    "8/6/2020",
    "8/7/2020",
    "8/8/2020",
    "8/9/2020",
    "8/10/2020",
    "8/11/2020",
    "8/12/2020",
    "8/13/2020",
    "8/14/2020",
    "8/15/2020",
    "8/16/2020",
    "8/17/2020",
    "8/18/2020",
    "8/19/2020",
    "8/20/2020",
    "8/21/2020",
    "8/22/2020",
    "8/23/2020",
    "8/24/2020",
    "8/25/2020",
    "8/26/2020",
    "8/27/2020",
    "8/28/2020",
    "8/29/2020",
    "8/30/2020",
    "8/31/2020",
    "9/1/2020",
    "9/2/2020",
    "9/3/2020",
    "9/4/2020",
    "9/5/2020",
    "9/6/2020",
    "9/7/2020",
    "9/8/2020",
    "9/9/2020",
    "9/10/2020",
    "9/11/2020",
    "9/12/2020",
    "9/13/2020",
    "9/14/2020",
    "9/15/2020",
    "9/16/2020",
    "9/17/2020",
    "9/18/2020",
    "9/19/2020",
    "9/20/2020",
    "9/21/2020",
    "9/22/2020",
    "9/23/2020",
    "9/24/2020",
    "9/25/2020",
    "9/26/2020",
    "9/27/2020",
    "9/28/2020",
    "9/29/2020",
    "9/30/2020",
    "10/1/2020",
    "10/2/2020",
    "10/3/2020",
    "10/4/2020",
    "10/5/2020",
    "10/6/2020",
    "10/7/2020",
    "10/8/2020",
    "10/9/2020",
    "10/10/2020",
    "10/11/2020",
    "10/12/2020",
    "10/13/2020",
    "10/14/2020",
    "10/15/2020",
    "10/16/2020",
    "10/17/2020",
    "10/18/2020",
    "10/19/2020",
    "10/20/2020",
    "10/21/2020",
    "10/22/2020",
    "10/23/2020",
    "10/24/2020",
    "10/25/2020",
    "10/26/2020",
    "10/27/2020",
    "10/28/2020",
    "10/29/2020",
    "10/30/2020",
    "10/31/2020",
    "11/1/2020",
    "11/2/2020",
    "11/3/2020",
    "11/4/2020",
    "11/5/2020",
    "11/6/2020",
    "11/7/2020",
    "11/8/2020",
    "11/9/2020",
    "11/10/2020",
    "11/11/2020",
    "11/12/2020",
    "11/13/2020",
    "11/14/2020",
    "11/15/2020",
    "11/16/2020",
    "11/17/2020",
    "11/18/2020",
    "11/19/2020",
    "11/20/2020",
    "11/21/2020",
    "11/22/2020",
    "11/23/2020",
    "11/24/2020",
    "11/25/2020",
    "11/26/2020",
    "11/27/2020",
    "11/28/2020",
    "11/29/2020",
    "11/30/2020",
    "12/1/2020",
    "12/2/2020",
    "12/3/2020",
    "12/4/2020",
    "12/5/2020",
    "12/6/2020",
    "12/7/2020",
    "12/8/2020",
    "12/9/2020",
    "12/10/2020",
    "12/11/2020",
    "12/12/2020",
    "12/13/2020",
    "12/14/2020",
    "12/15/2020",
    "12/16/2020",
    "12/17/2020",
    "12/18/2020",
    "12/19/2020",
    "12/20/2020",
    "12/21/2020",
    "12/22/2020",
    "12/23/2020",
    "12/24/2020",
    "12/25/2020",
    "12/26/2020",
    "12/27/2020",
    "12/28/2020",
    "12/29/2020",
    "12/30/2020",
    "12/31/2020",
    "1/1/2021",
    "1/2/2021",
    "1/3/2021",
    "1/4/2021",
    "1/5/2021",
    "1/6/2021",
    "1/7/2021",
    "1/8/2021",
    "1/9/2021",
    "1/10/2021",
    "1/11/2021",
    "1/12/2021",
    "1/13/2021",
    "1/14/2021",
    "1/15/2021",
    "1/16/2021",
    "1/17/2021",
    "1/18/2021",
    "1/19/2021",
    "1/20/2021",
    "1/21/2021",
    "1/22/2021",
    "1/23/2021",
    "1/24/2021",
    "1/25/2021",
    "1/26/2021",
    "1/27/2021",
    "1/28/2021",
    "1/29/2021",
    "1/30/2021",
    "1/31/2021",
    "2/1/2021",
    "2/2/2021",
    "2/3/2021",
    "2/4/2021",
    "2/5/2021",
    "2/6/2021",
    "2/7/2021",
    "2/8/2021",
    "2/9/2021",
    "2/10/2021",
    "2/11/2021",
    "2/12/2021",
    "2/13/2021",
    "2/14/2021",
    "2/15/2021",
    "2/16/2021",
    "2/17/2021",
    "2/18/2021",
    "2/19/2021",
    "2/20/2021",
    "2/21/2021",
    "2/22/2021",
    "2/23/2021",
    "2/24/2021",
    "2/25/2021",
    "2/26/2021",
    "2/27/2021",
    "2/28/2021",
    "3/1/2021",
    "3/2/2021",
    "3/3/2021",
    "3/4/2021",
    "3/5/2021",
    "3/6/2021",
    "3/7/2021",
    "3/8/2021",
    "3/9/2021",
    "3/10/2021",
    "3/11/2021",
    "3/12/2021",
    "3/13/2021",
    "3/14/2021",
    "3/15/2021",
    "3/16/2021",
    "3/17/2021",
    "3/18/2021",
    "3/19/2021",
    "3/20/2021",
    "3/21/2021",
    "3/22/2021",
    "3/23/2021",
    "3/24/2021",
    "3/25/2021",
    "3/26/2021",
    "3/27/2021",
    "3/28/2021",
    "3/29/2021",
    "3/30/2021",
    "3/31/2021",
    "4/1/2021",
    "4/2/2021",
    "4/3/2021",
    "4/4/2021",
    "4/5/2021",
    "4/6/2021",
    "4/7/2021",
    "4/8/2021",
    "4/9/2021",
    "4/10/2021",
    "4/11/2021",
    "4/12/2021",
    "4/13/2021",
    "4/14/2021",
    "4/15/2021",
    "4/16/2021",
    "4/17/2021",
    "4/18/2021",
    "4/19/2021",
    "4/20/2021",
    "4/21/2021",
    "4/22/2021",
    "4/23/2021",
    "4/24/2021",
    "4/25/2021",
    "4/26/2021",
    "4/27/2021",
    "4/28/2021",
    "4/29/2021",
    "4/30/2021",
    "5/1/2021",
    "5/2/2021",
    "5/3/2021",
    "5/4/2021",
    "5/5/2021",
    "5/6/2021",
    "5/7/2021",
    "5/8/2021",
    "5/9/2021",
    "5/10/2021",
    "5/11/2021",
    "5/12/2021",
    "5/13/2021",
    "5/14/2021",
    "5/15/2021",
    "5/16/2021",
    "5/17/2021",
    "5/18/2021",
    "5/19/2021",
    "5/20/2021",
    "5/21/2021",
    "5/22/2021",
    "5/23/2021",
    "5/24/2021",
    "5/25/2021",
    "5/26/2021",
    "5/27/2021",
    "5/28/2021",
    "5/29/2021",
    "5/30/2021",
    "5/31/2021",
    "6/1/2021",
    "6/2/2021",
    "6/3/2021",
    "6/4/2021",
    "6/5/2021",
    "6/6/2021",
    "6/7/2021",
    "6/8/2021",
    "6/9/2021",
    "6/10/2021",
    "6/11/2021",
    "6/12/2021",
    "6/13/2021",
    "6/14/2021",
    "6/15/2021",
    "6/16/2021",
    "6/17/2021",
    "6/18/2021",
    "6/19/2021",
    "6/20/2021",
    "6/21/2021",
    "6/22/2021",
    "6/23/2021",
    "6/24/2021",
    "6/25/2021",
    "6/26/2021",
    "6/27/2021",
    "6/28/2021",
    "6/29/2021",
    "6/30/2021",
    "7/1/2021",
    "7/2/2021",
    "7/3/2021",
    "7/4/2021",
    "7/5/2021",
    "7/6/2021",
    "7/7/2021",
    "7/8/2021",
    "7/9/2021",
    "7/10/2021",
    "7/11/2021",
    "7/12/2021",
    "7/13/2021",
    "7/14/2021",
    "7/15/2021",
    "7/16/2021",
    "7/17/2021",
    "7/18/2021",
    "7/19/2021",
    "7/20/2021",
    "7/21/2021",
    "7/22/2021",
    "7/23/2021",
    "7/24/2021",
    "7/25/2021",
    "7/26/2021",
    "7/27/2021",
    "7/28/2021",
    "7/29/2021",
    "7/30/2021",
    "7/31/2021",
    "8/1/2021",
    "8/2/2021",
    "8/3/2021",
    "8/4/2021",
    "8/5/2021",
    "8/6/2021",
    "8/7/2021",
    "8/8/2021",
    "8/9/2021",
    "8/10/2021",
    "8/11/2021",
    "8/12/2021",
    "8/13/2021",
    "8/14/2021",
    "8/15/2021",
    "8/16/2021",
    "8/17/2021",
    "8/18/2021",
    "8/19/2021",
    "8/20/2021",
    "8/21/2021",
    "8/22/2021",
    "8/23/2021",
    "8/24/2021",
    "8/25/2021",
    "8/26/2021",
    "8/27/2021",
    "8/28/2021",
    "8/29/2021",
    "8/30/2021",
    "8/31/2021",
    "9/1/2021",
    "9/2/2021",
    "9/3/2021",
    "9/4/2021",
    "9/5/2021",
    "9/6/2021",
    "9/7/2021",
    "9/8/2021",
    "9/9/2021",
    "9/10/2021",
    "9/11/2021",
    "9/12/2021",
    "9/13/2021",
    "9/14/2021",
    "9/15/2021",
    "9/16/2021",
    "9/17/2021",
    "9/18/2021",
    "9/19/2021",
    "9/20/2021",
    "9/21/2021",
    "9/22/2021",
    "9/23/2021",
    "9/24/2021",
    "9/25/2021",
    "9/26/2021",
    "9/27/2021",
    "9/28/2021",
    "9/29/2021",
    "9/30/2021",
    "10/1/2021",
    "10/2/2021",
    "10/3/2021",
    "10/4/2021",
    "10/5/2021",
    "10/6/2021",
    "10/7/2021",
    "10/8/2021",
    "10/9/2021",
    "10/10/2021",
    "10/11/2021",
    "10/12/2021",
    "10/13/2021",
    "10/14/2021",
    "10/15/2021",
    "10/16/2021",
    "10/17/2021",
    "10/18/2021",
    "10/19/2021",
    "10/20/2021",
    "10/21/2021",
    "10/22/2021",
    "10/23/2021",
    "10/24/2021",
    "10/25/2021",
    "10/26/2021",
    "10/27/2021",
    "10/28/2021",
    "10/29/2021",
    "10/30/2021",
    "10/31/2021",
    "11/1/2021",
    "11/2/2021",
    "11/3/2021",
    "11/4/2021",
    "11/5/2021",
    "11/6/2021",
    "11/7/2021",
    "11/8/2021",
    "11/9/2021",
    "11/10/2021",
    "11/11/2021",
    "11/12/2021",
    "11/13/2021",
    "11/14/2021",
    "11/15/2021",
    "11/16/2021",
    "11/17/2021",
    "11/18/2021",
    "11/19/2021",
    "11/20/2021",
    "11/21/2021",
    "11/22/2021",
    "11/23/2021",
    "11/24/2021",
    "11/25/2021",
    "11/26/2021",
    "11/27/2021",
    "11/28/2021",
    "11/29/2021",
    "11/30/2021",
    "12/1/2021",
    "12/2/2021",
    "12/3/2021",
    "12/4/2021",
    "12/5/2021",
    "12/6/2021",
    "12/7/2021",
    "12/8/2021",
    "12/9/2021",
    "12/10/2021",
    "12/11/2021",
    "12/12/2021",
    "12/13/2021",
    "12/14/2021",
    "12/15/2021",
    "12/16/2021",
    "12/17/2021",
    "12/18/2021",
    "12/19/2021",
    "12/20/2021",
    "12/21/2021",
    "12/22/2021",
    "12/23/2021",
    "12/24/2021",
    "12/25/2021",
    "12/26/2021",
    "12/27/2021",
    "12/28/2021",
    "12/29/2021",
    "12/30/2021",
    "12/31/2021",
    "1/1/2022",
    "1/2/2022",
    "1/3/2022",
    "1/4/2022",
    "1/5/2022",
    "1/6/2022",
    "1/7/2022",
    "1/8/2022",
    "1/9/2022",
    "1/10/2022",
    "1/11/2022",
    "1/12/2022",
    "1/13/2022",
    "1/14/2022",
    "1/15/2022",
    "1/16/2022",
    "1/17/2022",
    "1/18/2022",
    "1/19/2022",
    "1/20/2022",
    "1/21/2022",
    "1/22/2022",
    "1/23/2022",
    "1/24/2022",
    "1/25/2022",
    "1/26/2022",
    "1/27/2022",
    "1/28/2022",
    "1/29/2022",
    "1/30/2022",
    "1/31/2022",
    "2/1/2022",
    "2/2/2022",
    "2/3/2022",
    "2/4/2022",
    "2/5/2022",
    "2/6/2022",
    "2/7/2022",
    "2/8/2022",
    "2/9/2022",
    "2/10/2022",
    "2/11/2022",
    "2/12/2022",
    "2/13/2022",
    "2/14/2022",
    "2/15/2022",
    "2/16/2022",
    "2/17/2022",
    "2/18/2022",
    "2/19/2022",
    "2/20/2022",
    "2/21/2022",
    "2/22/2022",
    "2/23/2022",
    "2/24/2022",
    "2/25/2022",
    "2/26/2022",
    "2/27/2022",
    "2/28/2022",
    "3/1/2022",
    "3/2/2022",
    "3/3/2022",
    "3/4/2022",
    "3/5/2022",
    "3/6/2022",
    "3/7/2022",
    "3/8/2022",
    "3/9/2022",
    "3/10/2022",
    "3/11/2022",
    "3/12/2022",
    "3/13/2022",
    "3/14/2022",
    "3/15/2022",
    "3/16/2022",
    "3/17/2022",
    "3/18/2022",
    "3/19/2022",
    "3/20/2022",
    "3/21/2022",
    "3/22/2022",
    "3/23/2022",
    "3/24/2022",
    "3/25/2022",
    "3/26/2022",
    "3/27/2022",
    "3/28/2022",
    "3/29/2022",
    "3/30/2022",
    "3/31/2022",
    "4/1/2022",
    "4/2/2022",
    "4/3/2022",
    "4/4/2022",
    "4/5/2022",
    "4/6/2022",
    "4/7/2022",
    "4/8/2022",
    "4/9/2022",
    "4/10/2022",
    "4/11/2022",
    "4/12/2022",
    "4/13/2022",
    "4/14/2022",
    "4/15/2022",
    "4/16/2022",
    "4/17/2022",
    "4/18/2022",
    "4/19/2022",
    "4/20/2022",
    "4/21/2022",
    "4/22/2022",
    "4/23/2022",
    "4/24/2022",
    "4/25/2022",
    "4/26/2022",
    "4/27/2022",
    "4/28/2022",
    "4/29/2022",
    "4/30/2022",
    "5/1/2022",
    "5/2/2022",
    "5/3/2022",
    "5/4/2022",
    "5/5/2022",
    "5/6/2022",
    "5/7/2022",
    "5/8/2022",
    "5/9/2022",
    "5/10/2022",
    "5/11/2022",
    "5/12/2022",
    "5/13/2022",
    "5/14/2022",
    "5/15/2022",
    "5/16/2022",
    "5/17/2022",
    "5/18/2022",
    "5/19/2022",
    "5/20/2022",
    "5/21/2022",
    "5/22/2022",
    "5/23/2022",
    "5/24/2022",
    "5/25/2022",
    "5/26/2022",
    "5/27/2022",
    "5/28/2022",
    "5/29/2022",
    "5/30/2022",
    "5/31/2022",
    "6/1/2022",
    "6/2/2022",
    "6/3/2022",
    "6/4/2022",
    "6/5/2022",
    "6/6/2022",
    "6/7/2022",
    "6/8/2022",
    "6/9/2022",
    "6/10/2022",
    "6/11/2022",
    "6/12/2022",
    "6/13/2022",
    "6/14/2022",
    "6/15/2022",
    "6/16/2022",
    "6/17/2022",
    "6/18/2022",
    "6/19/2022",
    "6/20/2022",
    "6/21/2022",
    "6/22/2022",
    "6/23/2022",
    "6/24/2022",
    "6/25/2022",
    "6/26/2022",
    "6/27/2022",
    "6/28/2022",
    "6/29/2022",
    "6/30/2022",
    "7/1/2022",
    "7/2/2022",
    "7/3/2022",
    "7/4/2022",
    "7/5/2022",
    "7/6/2022",
    "7/7/2022",
    "7/8/2022",
    "7/9/2022",
    "7/10/2022",
    "7/11/2022",
    "7/12/2022",
    "7/13/2022",
    "7/14/2022",
    "7/15/2022",
    "7/16/2022",
    "7/17/2022",
    "7/18/2022",
    "7/19/2022",
    "7/20/2022",
    "7/21/2022",
    "7/22/2022",
    "7/23/2022",
    "7/24/2022",
    "7/25/2022",
    "7/26/2022",
    "7/27/2022",
    "7/28/2022",
    "7/29/2022",
    "7/30/2022",
    "7/31/2022",
    "8/1/2022",
    "8/2/2022",
    "8/3/2022",
    "8/4/2022",
    "8/5/2022",
    "8/6/2022",
    "8/7/2022",
    "8/8/2022",
    "8/9/2022",
    "8/10/2022",
    "8/11/2022",
    "8/12/2022",
    "8/13/2022",
    "8/14/2022",
    "8/15/2022",
    "8/16/2022",
    "8/17/2022",
    "8/18/2022",
    "8/19/2022",
    "8/20/2022",
    "8/21/2022",
    "8/22/2022",
    "8/23/2022",
    "8/24/2022",
    "8/25/2022",
    "8/26/2022",
    "8/27/2022",
    "8/28/2022",
    "8/29/2022",
    "8/30/2022",
    "8/31/2022",
    "9/1/2022",
    "9/2/2022",
    "9/3/2022",
    "9/4/2022",
    "9/5/2022",
    "9/6/2022",
    "9/7/2022",
    "9/8/2022",
    "9/9/2022",
    "9/10/2022",
    "9/11/2022",
    "9/12/2022",
    "9/13/2022",
    "9/14/2022",
    "9/15/2022",
    "9/16/2022",
    "9/17/2022",
    "9/18/2022",
    "9/19/2022",
    "9/20/2022",
    "9/21/2022",
    "9/22/2022",
    "9/23/2022",
    "9/24/2022",
    "9/25/2022",
    "9/26/2022",
    "9/27/2022",
    "9/28/2022",
    "9/29/2022",
    "9/30/2022",
    "10/1/2022",
    "10/2/2022",
    "10/3/2022",
    "10/4/2022",
    "10/5/2022",
    "10/6/2022",
    "10/7/2022",
    "10/8/2022",
    "10/9/2022",
    "10/10/2022",
    "10/11/2022",
    "10/12/2022",
    "10/13/2022",
    "10/14/2022",
    "10/15/2022",
    "10/16/2022",
    "10/17/2022",
    "10/18/2022",
    "10/19/2022",
    "10/20/2022",
    "10/21/2022",
    "10/22/2022",
    "10/23/2022",
    "10/24/2022",
    "10/25/2022",
    "10/26/2022",
    "10/27/2022",
    "10/28/2022",
    "10/29/2022",
    "10/30/2022",
    "10/31/2022",
    "11/1/2022",
    "11/2/2022",
    "11/3/2022",
    "11/4/2022",
    "11/5/2022",
    "11/6/2022",
    "11/7/2022",
    "11/8/2022",
    "11/9/2022",
    "11/10/2022",
    "11/11/2022",
    "11/12/2022",
    "11/13/2022",
    "11/14/2022",
    "11/15/2022",
    "11/16/2022",
    "11/17/2022",
    "11/18/2022",
    "11/19/2022",
    "11/20/2022",
    "11/21/2022",
    "11/22/2022",
    "11/23/2022",
    "11/24/2022",
    "11/25/2022",
    "11/26/2022",
    "11/27/2022",
    "11/28/2022",
    "11/29/2022",
    "11/30/2022",
    "12/1/2022",
    "12/2/2022",
    "12/3/2022",
    "12/4/2022",
    "12/5/2022",
    "12/6/2022",
    "12/7/2022",
    "12/8/2022",
    "12/9/2022",
    "12/10/2022",
    "12/11/2022",
    "12/12/2022",
    "12/13/2022",
    "12/14/2022",
    "12/15/2022",
    "12/16/2022",
    "12/17/2022",
    "12/18/2022",
    "12/19/2022",
    "12/20/2022",
    "12/21/2022",
    "12/22/2022",
    "12/23/2022",
    "12/24/2022",
    "12/25/2022",
    "12/26/2022",
    "12/27/2022",
    "12/28/2022",
    "12/29/2022",
    "12/30/2022",
    "12/31/2022",
    "1/1/2023",
    "1/2/2023",
    "1/3/2023",
    "1/4/2023",
    "1/5/2023",
    "1/6/2023",
    "1/7/2023",
    "1/8/2023",
    "1/9/2023",
    "1/10/2023",
    "1/11/2023",
    "1/12/2023",
    "1/13/2023",
    "1/14/2023",
    "1/15/2023",
    "1/16/2023",
    "1/17/2023",
    "1/18/2023",
    "1/19/2023",
    "1/20/2023",
    "1/21/2023",
    "1/22/2023",
    "1/23/2023",
    "1/24/2023",
    "1/25/2023",
    "1/26/2023",
    "1/27/2023",
    "1/28/2023",
    "1/29/2023",
    "1/30/2023",
    "1/31/2023",
    "2/1/2023",
    "2/2/2023",
    "2/3/2023",
    "2/4/2023",
    "2/5/2023",
    "2/6/2023",
    "2/7/2023",
    "2/8/2023",
    "2/9/2023",
    "2/10/2023",
    "2/11/2023",
    "2/12/2023",
    "2/13/2023",
    "2/14/2023",
    "2/15/2023",
    "2/16/2023",
    "2/17/2023",
    "2/18/2023",
    "2/19/2023",
    "2/20/2023",
    "2/21/2023",
    "2/22/2023",
    "2/23/2023",
    "2/24/2023",
    "2/25/2023",
    "2/26/2023",
    "2/27/2023",
    "2/28/2023",
    "3/1/2023",
    "3/2/2023",
    "3/3/2023",
    "3/4/2023",
    "3/5/2023",
    "3/6/2023",
    "3/7/2023",
    "3/8/2023",
    "3/9/2023",
    "3/10/2023",
    "3/11/2023",
    "3/12/2023",
    "3/13/2023",
    "3/14/2023",
    "3/15/2023",
    "3/16/2023",
    "3/17/2023",
    "3/18/2023",
    "3/19/2023",
    "3/20/2023",
    "3/21/2023",
    "3/22/2023",
    "3/23/2023",
    "3/24/2023",
    "3/25/2023",
    "3/26/2023",
    "3/27/2023",
    "3/28/2023",
    "3/29/2023",
    "3/30/2023",
    "3/31/2023",
    "4/1/2023",
    "4/2/2023",
    "4/3/2023",
    "4/4/2023",
    "4/5/2023",
    "4/6/2023",
    "4/7/2023",
    "4/8/2023",
    "4/9/2023",
    "4/10/2023",
    "4/11/2023",
    "4/12/2023",
    "4/13/2023",
    "4/14/2023",
    "4/15/2023",
    "4/16/2023",
    "4/17/2023",
    "4/18/2023",
    "4/19/2023",
    "4/20/2023",
    "4/21/2023",
    "4/22/2023",
    "4/23/2023",
    "4/24/2023",
    "4/25/2023",
    "4/26/2023",
    "4/27/2023",
    "4/28/2023",
    "4/29/2023",
    "4/30/2023",
    "5/1/2023",
    "5/2/2023",
    "5/3/2023",
    "5/4/2023",
    "5/5/2023",
    "5/6/2023",
    "5/7/2023",
    "5/8/2023",
    "5/9/2023",
    "5/10/2023",
    "5/11/2023",
    "5/12/2023",
    "5/13/2023",
    "5/14/2023",
    "5/15/2023",
    "5/16/2023",
    "5/17/2023",
    "5/18/2023",
    "5/19/2023",
    "5/20/2023",
    "5/21/2023",
    "5/22/2023",
    "5/23/2023",
    "5/24/2023",
    "5/25/2023",
    "5/26/2023",
    "5/27/2023",
    "5/28/2023",
    "5/29/2023",
    "5/30/2023",
    "5/31/2023",
    "6/1/2023",
    "6/2/2023",
    "6/3/2023",
    "6/4/2023",
    "6/5/2023",
    "6/6/2023",
    "6/7/2023",
    "6/8/2023",
    "6/9/2023",
    "6/10/2023",
    "6/11/2023",
    "6/12/2023",
    "6/13/2023",
    "6/14/2023",
    "6/15/2023",
    "6/16/2023",
    "6/17/2023",
    "6/18/2023",
    "6/19/2023",
    "6/20/2023",
    "6/21/2023",
    "6/22/2023",
    "6/23/2023",
    "6/24/2023",
    "6/25/2023",
    "6/26/2023",
    "6/27/2023",
    "6/28/2023",
    "6/29/2023",
    "6/30/2023",
    "7/1/2023",
    "7/2/2023",
    "7/3/2023",
    "7/4/2023",
    "7/5/2023",
    "7/6/2023",
    "7/7/2023",
    "7/8/2023",
    "7/9/2023",
    "7/10/2023",
    "7/11/2023",
    "7/12/2023",
    "7/13/2023",
    "7/14/2023",
    "7/15/2023",
    "7/16/2023",
    "7/17/2023",
    "7/18/2023",
    "7/19/2023",
    "7/20/2023",
    "7/21/2023",
    "7/22/2023",
    "7/23/2023",
    "7/24/2023",
    "7/25/2023",
    "7/26/2023",
    "7/27/2023",
    "7/28/2023",
    "7/29/2023",
    "7/30/2023",
    "7/31/2023",
    "8/1/2023",
    "8/2/2023",
    "8/3/2023",
    "8/4/2023",
    "8/5/2023",
    "8/6/2023",
    "8/7/2023",
    "8/8/2023",
    "8/9/2023",
    "8/10/2023",
    "8/11/2023",
    "8/12/2023",
    "8/13/2023",
    "8/14/2023",
    "8/15/2023",
    "8/16/2023",
    "8/17/2023",
    "8/18/2023",
    "8/19/2023",
    "8/20/2023",
    "8/21/2023",
    "8/22/2023",
    "8/23/2023",
    "8/24/2023",
    "8/25/2023",
    "8/26/2023",
    "8/27/2023",
    "8/28/2023",
    "8/29/2023",
    "8/30/2023",
    "8/31/2023",
    "9/1/2023",
    "9/2/2023",
    "9/3/2023",
    "9/4/2023",
    "9/5/2023",
    "9/6/2023",
    "9/7/2023",
    "9/8/2023",
    "9/9/2023",
    "9/10/2023",
    "9/11/2023",
    "9/12/2023",
    "9/13/2023",
    "9/14/2023",
    "9/15/2023",
    "9/16/2023",
    "9/17/2023",
    "9/18/2023",
    "9/19/2023",
    "9/20/2023",
    "9/21/2023",
    "9/22/2023",
    "9/23/2023",
    "9/24/2023",
    "9/25/2023",
    "9/26/2023",
    "9/27/2023",
    "9/28/2023",
    "9/29/2023",
    "9/30/2023",
    "10/1/2023",
    "10/2/2023",
    "10/3/2023",
    "10/4/2023",
    "10/5/2023",
    "10/6/2023",
    "10/7/2023",
    "10/8/2023",
    "10/9/2023",
    "10/10/2023",
    "10/11/2023",
    "10/12/2023",
    "10/13/2023",
    "10/14/2023",
    "10/15/2023",
    "10/16/2023",
    "10/17/2023",
    "10/18/2023",
    "10/19/2023",
    "10/20/2023",
    "10/21/2023",
    "10/22/2023",
    "10/23/2023",
    "10/24/2023",
    "10/25/2023",
    "10/26/2023",
    "10/27/2023",
    "10/28/2023",
    "10/29/2023",
    "10/30/2023",
    "10/31/2023",
    "11/1/2023",
    "11/2/2023",
    "11/3/2023",
    "11/4/2023",
    "11/5/2023",
    "11/6/2023",
    "11/7/2023",
    "11/8/2023",
    "11/9/2023",
    "11/10/2023",
    "11/11/2023",
    "11/12/2023",
    "11/13/2023",
    "11/14/2023",
    "11/15/2023",
    "11/16/2023",
    "11/17/2023",
    "11/18/2023",
    "11/19/2023",
    "11/20/2023",
    "11/21/2023",
    "11/22/2023",
    "11/23/2023",
    "11/24/2023",
    "11/25/2023",
    "11/26/2023",
    "11/27/2023",
    "11/28/2023",
    "11/29/2023",
    "11/30/2023",
    "12/1/2023",
    "12/2/2023",
    "12/3/2023",
    "12/4/2023",
    "12/5/2023",
    "12/6/2023",
    "12/7/2023",
    "12/8/2023",
    "12/9/2023",
    "12/10/2023",
    "12/11/2023",
    "12/12/2023",
    "12/13/2023",
    "12/14/2023",
    "12/15/2023",
    "12/16/2023",
    "12/17/2023",
    "12/18/2023",
    "12/19/2023",
    "12/20/2023",
    "12/21/2023",
    "12/22/2023",
    "12/23/2023",
    "12/24/2023",
    "12/25/2023",
    "12/26/2023",
    "12/27/2023",
    "12/28/2023",
    "12/29/2023",
    "12/30/2023",
    "12/31/2023",
    "1/1/2024",
    "1/2/2024",
    "1/3/2024",
    "1/4/2024",
    "1/5/2024",
    "1/6/2024",
    "1/7/2024",
    "1/8/2024",
    "1/9/2024",
    "1/10/2024",
    "1/11/2024",
    "1/12/2024",
    "1/13/2024",
    "1/14/2024",
    "1/15/2024",
    "1/16/2024",
    "1/17/2024",
    "1/18/2024",
    "1/19/2024",
    "1/20/2024",
    "1/21/2024",
    "1/22/2024",
    "1/23/2024",
    "1/24/2024",
    "1/25/2024",
    "1/26/2024",
    "1/27/2024",
    "1/28/2024",
    "1/29/2024",
    "1/30/2024",
    "1/31/2024",
    "2/1/2024",
    "2/2/2024",
    "2/3/2024",
    "2/4/2024",
    "2/5/2024",
    "2/6/2024",
    "2/7/2024",
    "2/8/2024",
    "2/9/2024",
    "2/10/2024",
    "2/11/2024",
    "2/12/2024",
    "2/13/2024",
    "2/14/2024",
    "2/15/2024",
    "2/16/2024",
    "2/17/2024",
    "2/18/2024",
    "2/19/2024",
    "2/20/2024",
    "2/21/2024",
    "2/22/2024",
    "2/23/2024",
    "2/24/2024",
    "2/25/2024",
    "2/26/2024",
    "2/27/2024",
    "2/28/2024",
    "2/29/2024",
    "3/1/2024",
    "3/2/2024",
    "3/3/2024",
    "3/4/2024",
    "3/5/2024",
    "3/6/2024",
    "3/7/2024",
    "3/8/2024",
    "3/9/2024",
    "3/10/2024",
    "3/11/2024",
    "3/12/2024",
    "3/13/2024",
    "3/14/2024",
    "3/15/2024",
    "3/16/2024",
    "3/17/2024",
    "3/18/2024",
    "3/19/2024",
    "3/20/2024",
    "3/21/2024",
    "3/22/2024",
    "3/23/2024",
    "3/24/2024",
    "3/25/2024",
    "3/26/2024",
    "3/27/2024",
    "3/28/2024",
    "3/29/2024",
    "3/30/2024",
    "3/31/2024",
    "4/1/2024",
    "4/2/2024",
    "4/3/2024",
    "4/4/2024",
    "4/5/2024",
    "4/6/2024",
    "4/7/2024",
    "4/8/2024",
    "4/9/2024",
    "4/10/2024",
    "4/11/2024",
    "4/12/2024",
    "4/13/2024",
    "4/14/2024",
    "4/15/2024",
    "4/16/2024",
    "4/17/2024",
    "4/18/2024",
    "4/19/2024",
    "4/20/2024",
    "4/21/2024",
    "4/22/2024",
    "4/23/2024",
    "4/24/2024",
    "4/25/2024",
    "4/26/2024",
    "4/27/2024",
    "4/28/2024",
    "4/29/2024",
    "4/30/2024",
    "5/1/2024",
    "5/2/2024",
    "5/3/2024",
    "5/4/2024",
    "5/5/2024",
    "5/6/2024",
    "5/7/2024",
    "5/8/2024",
    "5/9/2024",
    "5/10/2024",
    "5/11/2024",
    "5/12/2024",
    "5/13/2024",
    "5/14/2024",
    "5/15/2024",
    "5/16/2024",
    "5/17/2024",
    "5/18/2024",
    "5/19/2024",
    "5/20/2024",
    "5/21/2024",
    "5/22/2024",
    "5/23/2024",
    "5/24/2024",
    "5/25/2024",
    "5/26/2024",
    "5/27/2024",
    "5/28/2024",
    "5/29/2024",
    "5/30/2024",
    "5/31/2024",
    "6/1/2024",
    "6/2/2024",
    "6/3/2024",
    "6/4/2024",
    "6/5/2024",
    "6/6/2024",
    "6/7/2024",
    "6/8/2024",
    "6/9/2024",
    "6/10/2024",
    "6/11/2024",
    "6/12/2024",
    "6/13/2024",
    "6/14/2024",
    "6/15/2024",
    "6/16/2024",
    "6/17/2024",
    "6/18/2024",
    "6/19/2024",
    "6/20/2024",
    "6/21/2024",
    "6/22/2024",
    "6/23/2024",
    "6/24/2024",
    "6/25/2024",
    "6/26/2024",
    "6/27/2024",
    "6/28/2024",
    "6/29/2024",
    "6/30/2024",
    "7/1/2024",
    "7/2/2024",
    "7/3/2024",
    "7/4/2024",
    "7/5/2024",
    "7/6/2024",
    "7/7/2024",
    "7/8/2024",
    "7/9/2024",
    "7/10/2024",
    "7/11/2024",
    "7/12/2024",
    "7/13/2024",
    "7/14/2024",
    "7/15/2024",
    "7/16/2024",
    "7/17/2024",
    "7/18/2024",
    "7/19/2024",
    "7/20/2024",
    "7/21/2024",
    "7/22/2024",
    "7/23/2024",
    "7/24/2024",
    "7/25/2024",
    "7/26/2024",
    "7/27/2024",
    "7/28/2024",
    "7/29/2024",
    "7/30/2024",
    "7/31/2024",
    "8/1/2024",
    "8/2/2024",
    "8/3/2024",
    "8/4/2024",
    "8/5/2024",
    "8/6/2024",
    "8/7/2024",
    "8/8/2024",
    "8/9/2024",
    "8/10/2024",
    "8/11/2024",
    "8/12/2024",
    "8/13/2024",
    "8/14/2024",
    "8/15/2024",
    "8/16/2024",
    "8/17/2024",
    "8/18/2024",
    "8/19/2024",
    "8/20/2024",
    "8/21/2024",
    "8/22/2024",
    "8/23/2024",
    "8/24/2024",
    "8/25/2024",
    "8/26/2024",
    "8/27/2024",
    "8/28/2024",
    "8/29/2024",
    "8/30/2024",
    "8/31/2024",
    "9/1/2024",
    "9/2/2024",
    "9/3/2024",
    "9/4/2024",
    "9/5/2024",
    "9/6/2024",
    "9/7/2024",
    "9/8/2024",
    "9/9/2024",
    "9/10/2024",
    "9/11/2024",
    "9/12/2024",
    "9/13/2024",
    "9/14/2024",
    "9/15/2024",
    "9/16/2024",
    "9/17/2024",
    "9/18/2024",
    "9/19/2024",
    "9/20/2024",
    "9/21/2024",
    "9/22/2024",
    "9/23/2024",
    "9/24/2024",
    "9/25/2024",
    "9/26/2024",
    "9/27/2024",
    "9/28/2024",
    "9/29/2024",
    "9/30/2024",
    "10/1/2024",
    "10/2/2024",
    "10/3/2024",
    "10/4/2024",
    "10/5/2024",
    "10/6/2024",
    "10/7/2024",
    "10/8/2024",
    "10/9/2024",
    "10/10/2024",
    "10/11/2024",
    "10/12/2024",
    "10/13/2024",
    "10/14/2024",
    "10/15/2024",
    "10/16/2024",
    "10/17/2024",
    "10/18/2024",
    "10/19/2024",
    "10/20/2024",
    "10/21/2024",
    "10/22/2024",
    "10/23/2024",
    "10/24/2024",
    "10/25/2024",
    "10/26/2024",
    "10/27/2024",
    "10/28/2024",
    "10/29/2024",
    "10/30/2024",
    "10/31/2024",
    "11/1/2024",
    "11/2/2024",
    "11/3/2024",
    "11/4/2024",
    "11/5/2024",
    "11/6/2024",
    "11/7/2024",
    "11/8/2024",
    "11/9/2024",
    "11/10/2024",
    "11/11/2024",
    "11/12/2024",
    "11/13/2024",
    "11/14/2024",
    "11/15/2024",
    "11/16/2024",
    "11/17/2024",
    "11/18/2024",
    "11/19/2024",
    "11/20/2024",
    "11/21/2024",
    "11/22/2024",
    "11/23/2024",
    "11/24/2024",
    "11/25/2024",
    "11/26/2024",
    "11/27/2024",
    "11/28/2024",
    "11/29/2024",
    "11/30/2024",
    "12/1/2024",
    "12/2/2024",
    "12/3/2024",
    "12/4/2024",
    "12/5/2024",
    "12/6/2024",
    "12/7/2024",
    "12/8/2024",
    "12/9/2024",
    "12/10/2024",
    "12/11/2024",
    "12/12/2024",
    "12/13/2024",
    "12/14/2024",
    "12/15/2024",
  ],
  NetValue: [
    1, 1, 1.0005, 1.001, 1.0016, 1.0031, 1.0046, 1.0051, 1.0057, 1.0072, 1.0077,
    1.0072, 1.0078, 1.0073, 1.0088, 1.0083, 1.0078, 1.0094, 1.0099, 1.0114,
    1.0109, 1.0115, 1.011, 1.0105, 1.01, 1.0118, 1.0116, 1.0134, 1.0132, 1.015,
    1.0148, 1.0156, 1.0154, 1.0173, 1.017, 1.0168, 1.0176, 1.0185, 1.0193,
    1.0211, 1.0209, 1.0227, 1.0225, 1.0233, 1.0252, 1.026, 1.0258, 1.0255,
    1.0264, 1.0272, 1.028, 1.0288, 1.0307, 1.0325, 1.0323, 1.0321, 1.0322,
    1.0343, 1.0344, 1.0355, 1.0355, 1.0377, 1.0398, 1.0399, 1.042, 1.0432,
    1.0443, 1.0454, 1.0475, 1.0497, 1.0508, 1.052, 1.052, 1.0532, 1.0543,
    1.0544, 1.0544, 1.0566, 1.0577, 1.0599, 1.06, 1.0601, 1.0623, 1.0623,
    1.0645, 1.0657, 1.0668, 1.0666, 1.0685, 1.0694, 1.0702, 1.0721, 1.074,
    1.076, 1.0779, 1.0777, 1.0775, 1.0772, 1.0781, 1.079, 1.0787, 1.0807,
    1.0804, 1.0802, 1.08, 1.0798, 1.0796, 1.0804, 1.0802, 1.08, 1.0808, 1.0828,
    1.0836, 1.0856, 1.0854, 1.0862, 1.086, 1.086, 1.087, 1.0881, 1.0881, 1.088,
    1.088, 1.0902, 1.09, 1.0888, 1.0887, 1.0886, 1.0896, 1.0884, 1.0894, 1.0893,
    1.0892, 1.0914, 1.0924, 1.0912, 1.0911, 1.091, 1.0899, 1.091, 1.0921,
    1.0921, 1.093, 1.0941, 1.0952, 1.0974, 1.0984, 1.0984, 1.0994, 1.0982,
    1.0992, 1.1002, 1.1012, 1.1011, 1.1022, 1.1043, 1.1064, 1.1097, 1.1097,
    1.1107, 1.1117, 1.1128, 1.1139, 1.1149, 1.1161, 1.1171, 1.1205, 1.1226,
    1.1237, 1.1259, 1.1315, 1.1349, 1.1393, 1.1426, 1.1448, 1.1459, 1.147,
    1.147, 1.1446, 1.1491, 1.1491, 1.1491, 1.1512, 1.1512, 1.1511, 1.1522,
    1.1544, 1.1555, 1.1555, 1.1578, 1.1565, 1.1559, 1.1559, 1.1559, 1.1547,
    1.1592, 1.1602, 1.1614, 1.1636, 1.1658, 1.1657, 1.1679, 1.1654, 1.1688,
    1.1665, 1.1687, 1.1756, 1.1731, 1.1767, 1.1801, 1.1836, 1.1811, 1.1764,
    1.1846, 1.1881, 1.1809, 1.1961, 1.1949, 1.1913, 1.1996, 1.2067, 1.2078,
    1.2054, 1.203, 1.203, 1.2065, 1.2077, 1.2077, 1.2077, 1.2077, 1.2089,
    1.2089, 1.2089, 1.2137, 1.215, 1.215, 1.2162, 1.2198, 1.2259, 1.2296,
    1.2321, 1.2329, 1.2353, 1.2385, 1.2462, 1.2459, 1.254, 1.2605, 1.2641,
    1.2787, 1.2877, 1.2908, 1.2936, 1.3068, 1.316, 1.3257, 1.3281, 1.3304,
    1.3353, 1.3378, 1.3488, 1.3572, 1.3607, 1.3653, 1.3708, 1.3717, 1.3698,
    1.3758, 1.3747, 1.3748, 1.3752, 1.3775, 1.3707, 1.371, 1.3702, 1.371,
    1.3721, 1.3741, 1.3753, 1.3775, 1.3783, 1.3826, 1.3877, 1.3876, 1.3892,
    1.392, 1.3941, 1.3965, 1.3978, 1.3997, 1.4006, 1.4034, 1.4028, 1.4046,
    1.4065, 1.4067, 1.4075, 1.4128, 1.4108, 1.4221, 1.4277, 1.4381, 1.4382,
    1.4441, 1.4475, 1.4566, 1.4558, 1.4584, 1.4624, 1.4687, 1.4769, 1.4813,
    1.4895, 1.4922, 1.4984, 1.5072, 1.5244, 1.5249, 1.5258, 1.5285, 1.5295,
    1.5315, 1.5347, 1.5349, 1.535, 1.5348, 1.5351, 1.5358, 1.5365, 1.5374,
    1.5392, 1.5406, 1.5437, 1.5453, 1.5477, 1.5509, 1.5535, 1.5571, 1.5603,
    1.5647, 1.5652, 1.5683, 1.5689, 1.5691, 1.57, 1.5709, 1.5718, 1.5928, 1.618,
    1.6191, 1.6198, 1.6201, 1.6207, 1.6209, 1.621, 1.6211, 1.6213, 1.6216,
    1.6218, 1.6219, 1.6221, 1.6223, 1.6223, 1.6218, 1.6221, 1.6224, 1.6225,
    1.6223, 1.622, 1.6218, 1.6217, 1.6219, 1.622, 1.6222, 1.6223, 1.6223,
    1.6223, 1.6222, 1.6223, 1.6224, 1.6221, 1.6223, 1.6226, 1.6226, 1.6225,
    1.6225, 1.6226, 1.6224, 1.6224, 1.6223, 1.6222, 1.6222, 1.6217, 1.6216,
    1.6217, 1.6218, 1.6218, 1.6222, 1.6226, 1.6226, 1.6228, 1.623, 1.6233,
    1.6237, 1.6238, 1.6237, 1.6235, 1.6239, 1.6238, 1.6229, 1.6218, 1.6235,
    1.6237, 1.6241, 1.624, 1.6244, 1.6241, 1.6245, 1.6248, 1.6253, 1.6256,
    1.6253, 1.626, 1.6262, 1.6267, 1.6268, 1.6271, 1.6274, 1.6282, 1.6294,
    1.6298, 1.6303, 1.6325, 1.633, 1.6331, 1.6347, 1.6371, 1.6387, 1.6391,
    1.639, 1.6405, 1.6413, 1.6436, 1.6457, 1.6481, 1.6489, 1.6508, 1.6497,
    1.6501, 1.6514, 1.6532, 1.654, 1.6554, 1.6575, 1.6611, 1.6644, 1.6681,
    1.6713, 1.6687, 1.6686, 1.6682, 1.6685, 1.6684, 1.668, 1.669, 1.6695,
    1.6694, 1.6693, 1.6705, 1.67, 1.6712, 1.6724, 1.6727, 1.6716, 1.6722,
    1.6721, 1.6727, 1.6731, 1.673, 1.6733, 1.6731, 1.6733, 1.6733, 1.6739,
    1.6756, 1.677, 1.6777, 1.6783, 1.6824, 1.6865, 1.6881, 1.691, 1.6912,
    1.6919, 1.6922, 1.6927, 1.6939, 1.6972, 1.6994, 1.7009, 1.7016, 1.703,
    1.7066, 1.7088, 1.7102, 1.7149, 1.7158, 1.7194, 1.7254, 1.7258, 1.7273,
    1.7282, 1.7301, 1.732, 1.7331, 1.7363, 1.7396, 1.7415, 1.7424, 1.743,
    1.7449, 1.7481, 1.751, 1.7502, 1.7517, 1.7525, 1.7533, 1.7551, 1.756,
    1.7551, 1.7558, 1.7561, 1.7566, 1.7578, 1.7582, 1.7587, 1.7595, 1.7595,
    1.7631, 1.7617, 1.7627, 1.7623, 1.7632, 1.7626, 1.7632, 1.7673, 1.7668,
    1.7674, 1.7664, 1.768, 1.7687, 1.7681, 1.769, 1.7689, 1.7687, 1.7685, 1.769,
    1.7692, 1.769, 1.7691, 1.7694, 1.7696, 1.7709, 1.7711, 1.7709, 1.7712,
    1.7718, 1.7717, 1.7719, 1.7721, 1.7736, 1.7744, 1.7737, 1.7737, 1.774,
    1.7734, 1.775, 1.7745, 1.7767, 1.7756, 1.7781, 1.7774, 1.7779, 1.777,
    1.7787, 1.7803, 1.7796, 1.7796, 1.7786, 1.7807, 1.7802, 1.7802, 1.781,
    1.782, 1.7807, 1.7811, 1.7819, 1.7814, 1.7809, 1.7815, 1.7823, 1.7814,
    1.7815, 1.7815, 1.7812, 1.7825, 1.7822, 1.7823, 1.7827, 1.7825, 1.7844,
    1.7853, 1.7858, 1.7855, 1.7856, 1.7868, 1.7877, 1.7873, 1.7866, 1.7876,
    1.7906, 1.7891, 1.7894, 1.7896, 1.792, 1.7915, 1.7917, 1.7926, 1.7904,
    1.7922, 1.7926, 1.7921, 1.7916, 1.7937, 1.7935, 1.7945, 1.7955, 1.795,
    1.7964, 1.7957, 1.7967, 1.7983, 1.7996, 1.8006, 1.8005, 1.7999, 1.8008,
    1.8012, 1.8023, 1.8038, 1.8033, 1.8042, 1.8054, 1.8075, 1.808, 1.8097,
    1.8108, 1.8125, 1.813, 1.8149, 1.8185, 1.8189, 1.8212, 1.8233, 1.8235,
    1.8252, 1.8249, 1.8252, 1.8265, 1.8268, 1.8271, 1.8281, 1.8281, 1.8296,
    1.831, 1.8308, 1.8329, 1.8348, 1.8344, 1.8359, 1.8358, 1.8367, 1.8367,
    1.8381, 1.8388, 1.8391, 1.8398, 1.8397, 1.8401, 1.8406, 1.8405, 1.8414,
    1.8421, 1.8412, 1.8407, 1.8414, 1.841, 1.8432, 1.8421, 1.8417, 1.8415,
    1.8403, 1.8393, 1.8387, 1.8729, 1.8743, 1.8753, 1.8767, 1.8782, 1.878,
    1.8782, 1.8779, 1.8801, 1.8799, 1.88, 1.88, 1.8794, 1.8839, 1.8847, 1.883,
    1.8863, 1.888, 1.8897, 1.8933, 1.8946, 1.8967, 1.9021, 1.9068, 1.9091,
    1.9104, 1.9132, 1.913, 1.9138, 1.9146, 1.9154, 1.9161, 1.9166, 1.9192,
    1.9239, 1.9261, 1.9274, 1.9303, 1.9318, 1.9345, 1.9351, 1.936, 1.9355,
    1.9367, 1.937, 1.9424, 1.9462, 1.9444, 1.9452, 1.9456, 1.9457, 1.9451,
    1.9454, 1.9468, 1.9485, 1.9513, 1.9501, 1.9521, 1.9534, 1.9548, 1.9574,
    1.9575, 1.9601, 1.959, 1.9637, 1.9636, 1.9651, 1.9674, 1.971, 1.9729,
    1.9731, 1.973, 1.9736, 1.9746, 1.9737, 1.9735, 1.9738, 1.9743, 1.9741,
    1.9748, 1.975, 1.9752, 1.9754, 1.9765, 1.9769, 1.9771, 1.9779, 1.979,
    1.9795, 1.9797, 1.9807, 1.9808, 1.9818, 1.9822, 1.9825, 1.9824, 1.983,
    1.9834, 1.9836, 1.9837, 1.9835, 1.9837, 1.9839, 1.9841, 1.9843, 1.9846,
    1.9848, 1.9849, 1.9849, 1.9849, 1.985, 1.9856, 1.9856, 1.9857, 1.9858,
    1.9859, 1.9861, 1.9862, 1.9862, 1.986, 1.9861, 1.9864, 1.9866, 1.9876,
    1.9886, 1.9883, 1.9892, 1.9923, 1.9911, 1.9909, 1.9909, 1.992, 1.9932,
    1.992, 1.9997, 1.9999, 2.0005, 2.0016, 2.0027, 2.0038, 2.0051, 2.0059,
    2.0063, 2.0065, 2.0069, 2.0095, 2.0119, 2.0127, 2.0137, 2.0147, 2.0152,
    2.0158, 2.0157, 2.0172, 2.0182, 2.0188, 2.0192, 2.0196, 2.0199, 2.0207,
    2.021, 2.0207, 2.0208, 2.021, 2.0213, 2.0221, 2.0226, 2.0235, 2.0233,
    2.0237, 2.0239, 2.0243, 2.0245, 2.0255, 2.0257, 2.0264, 2.0167, 2.0172,
    2.0176, 2.0179, 2.0182, 2.02, 2.0119, 2.0303, 2.0331, 2.0323, 2.031, 2.0364,
    2.0413, 2.0416, 2.0432, 2.0458, 2.0466, 2.0458, 2.0571, 2.0585, 2.0585,
    2.0607, 2.0613, 2.0619, 2.0619, 2.0621, 2.0619, 2.0615, 2.0617, 2.0639,
    2.0652, 2.0651, 2.0656, 2.066, 2.0672, 2.0681, 2.0681, 2.0692, 2.0705,
    2.0698, 2.0721, 2.073, 2.0725, 2.0703, 2.0716, 2.073, 2.0723, 2.0772,
    2.0783, 2.0799, 2.0815, 2.0817, 2.0818, 2.0829, 2.0828, 2.0825, 2.0833,
    2.0842, 2.0847, 2.0849, 2.085, 2.0855, 2.0858, 2.0874, 2.0878, 2.089,
    2.0899, 2.0907, 2.0915, 2.0922, 2.0922, 2.093, 2.0955, 2.0988, 2.1017,
    2.1019, 2.1024, 2.1048, 2.1043, 2.1067, 2.1088, 2.1099, 2.1121, 2.1127,
    2.1122, 2.1127, 2.1142, 2.1147, 2.1164, 2.1167, 2.1178, 2.1186, 2.122,
    2.1234, 2.1269, 2.1294, 2.1293, 2.1322, 2.1336, 2.133, 2.1339, 2.1331,
    2.134, 2.1342, 2.1341, 2.1365, 2.1372, 2.1382, 2.1397, 2.1414, 2.1433,
    2.1442, 2.1444, 2.1453, 2.1448, 2.1456, 2.1464, 2.1473, 2.1479, 2.1486,
    2.1491, 2.1489, 2.1488, 2.1489, 2.1485, 2.1484, 2.1484, 2.1481, 2.1478,
    2.1494, 2.1484, 2.1489, 2.1487, 2.1488, 2.1495, 2.1494, 2.1491, 2.1497,
    2.1497, 2.1505, 2.1506, 2.1509, 2.1511, 2.1518, 2.1516, 2.1522, 2.1527,
    2.1525, 2.1531, 2.1536, 2.1543, 2.1543, 2.1552, 2.1552, 2.1555, 2.1559,
    2.1561, 2.1567, 2.1571, 2.1575, 2.1583, 2.158, 2.159, 2.1607, 2.1628,
    2.1656, 2.167, 2.1706, 2.1718, 2.1725, 2.1722, 2.1728, 2.1733, 2.1733,
    2.1739, 2.1745, 2.1753, 2.1753, 2.1761, 2.177, 2.178, 2.1786, 2.1791, 2.18,
    2.1809, 2.1812, 2.1818, 2.1812, 2.181, 2.1811, 2.1818, 2.1823, 2.1821,
    2.1821, 2.1826, 2.1835, 2.1836, 2.1838, 2.1845, 2.185, 2.1853, 2.1863,
    2.1867, 2.1868, 2.1873, 2.1879, 2.1887, 2.1895, 2.1909, 2.1912, 2.1916,
    2.1921, 2.1928, 2.1936, 2.1961, 2.1946, 2.1956, 2.1956, 2.1953, 2.196,
    2.1953, 2.196, 2.196, 2.1958, 2.1967, 2.1973, 2.1973, 2.1972, 2.1977,
    2.1977, 2.1974, 2.1966, 2.1971, 2.1976, 2.197, 2.1971, 2.1972, 2.197,
    2.1973, 2.1973, 2.1991, 2.2006, 2.2018, 2.2047, 2.2059, 2.2047, 2.2055,
    2.2061, 2.2066, 2.2081, 2.2086, 2.2092, 2.2114, 2.216, 2.2156, 2.2176,
    2.2188, 2.2196, 2.2213, 2.2215, 2.2234, 2.224, 2.2258, 2.2261, 2.2266,
    2.2263, 2.2258, 2.2277, 2.2284, 2.2297, 2.2311, 2.2313, 2.2319, 2.2324,
    2.2326, 2.2331, 2.233, 2.2335, 2.2336, 2.2337, 2.234, 2.2343, 2.2346,
    2.2349, 2.2355, 2.2361, 2.2363, 2.2364, 2.2361, 2.2364, 2.2363, 2.2363,
    2.2363, 2.2367, 2.2366, 2.2366, 2.2368, 2.2368, 2.2372, 2.2373, 2.2375,
    2.2374, 2.2375, 2.2375, 2.2377, 2.238, 2.2383, 2.2384, 2.2387, 2.2389,
    2.2388, 2.2388, 2.2387, 2.2386, 2.2391, 2.2394, 2.2396, 2.2399, 2.24,
    2.2406, 2.2409, 2.2411, 2.2418, 2.2424, 2.2425, 2.2425, 2.2437, 2.2443,
    2.2447, 2.2449, 2.2452, 2.2456, 2.2467, 2.2474, 2.2475, 2.2479, 2.2482,
    2.2485, 2.2484, 2.2488, 2.249, 2.2493, 2.2494, 2.2495, 2.2497, 2.2499,
    2.2501, 2.2505, 2.2507, 2.251, 2.2512, 2.2523, 2.253, 2.2525, 2.2547,
    2.2553, 2.257, 2.2591, 2.2615, 2.2629, 2.2645, 2.2671, 2.2696, 2.2704,
    2.2748, 2.2769, 2.2782, 2.283, 2.2846, 2.2879, 2.2892, 2.2926, 2.2946,
    2.2976, 2.3018, 2.307, 2.31, 2.3101, 2.3139, 2.3147, 2.3145, 2.3154, 2.3168,
    2.3195, 2.3192, 2.3208, 2.3221, 2.3234, 2.3257, 2.3275, 2.3277, 2.3303,
    2.3309, 2.3315, 2.3323, 2.3351, 2.338, 2.3408, 2.3454, 2.3463, 2.3501,
    2.3561, 2.3591, 2.3626, 2.3638, 2.3655, 2.3669, 2.3709, 2.3733, 2.3762,
    2.3775, 2.378, 2.3786, 2.3812, 2.3852, 2.3896, 2.394, 2.3968, 2.4044,
    2.4056, 2.4142, 2.4198, 2.4253, 2.43, 2.4328, 2.4388, 2.4458, 2.447, 2.4479,
    2.4477, 2.4488, 2.4465, 2.4475, 2.4482, 2.4489, 2.4505, 2.4511, 2.4524,
    2.4535, 2.4541, 2.4543, 2.4562, 2.4572, 2.458, 2.4591, 2.4591, 2.4596,
    2.4598, 2.4606, 2.4609, 2.4623, 2.4626, 2.4629, 2.4632, 2.4642, 2.4647,
    2.4653, 2.4666, 2.4674, 2.4684, 2.469, 2.47, 2.4703, 2.4704, 2.4723, 2.4741,
    2.4748, 2.479, 2.482, 2.4864, 2.4921, 2.496, 2.498, 2.5006, 2.503, 2.5045,
    2.507, 2.5098, 2.5122, 2.5157, 2.5201, 2.5265, 2.5336, 2.5408, 2.5507,
    2.5605, 2.5727, 2.5878, 2.602, 2.6144, 2.6264, 2.6387, 2.6524, 2.6636,
    2.6752, 2.6881, 2.6984, 2.708, 2.7156, 2.7216, 2.7267, 2.7317, 2.7332,
    2.736, 2.738, 2.7424, 2.7448, 2.7488, 2.7503, 2.7536, 2.7573, 2.7629,
    2.7696, 2.7769, 2.7829, 2.7927, 2.7951, 2.7976, 2.7987, 2.8002, 2.8016,
    2.8027, 2.804, 2.8082, 2.8091, 2.8111, 2.8138, 2.8132, 2.8129, 2.8127,
    2.8126, 2.8125, 2.8125, 2.8124, 2.8124, 2.8124, 2.8125, 2.8121, 2.8131,
    2.8131, 2.8131, 2.8144, 2.8137, 2.8128, 2.8132, 2.8131, 2.8139, 2.8147,
    2.8148, 2.8152, 2.8162, 2.8165, 2.8175, 2.8178, 2.8179, 2.818, 2.8181,
    2.8177, 2.819, 2.8191, 2.8199, 2.8206, 2.8206, 2.8217, 2.8212, 2.8239,
    2.8259, 2.8283, 2.8291, 2.8309, 2.8327, 2.834, 2.8365, 2.8423, 2.8439,
    2.8455, 2.8465, 2.8497, 2.8511, 2.8526, 2.8536, 2.8577, 2.8601, 2.8598,
    2.8594, 2.8594, 2.8595, 2.8588, 2.8594, 2.8597, 2.8597, 2.8596, 2.8603,
    2.8591, 2.8594, 2.8588, 2.8595, 2.8594, 2.8589, 2.8602, 2.8596, 2.8603,
    2.8604, 2.861, 2.8608, 2.8607, 2.8609, 2.8618, 2.862, 2.862, 2.8617, 2.8613,
    2.8614, 2.8618, 2.8618, 2.8617, 2.862, 2.8621, 2.8621, 2.8622, 2.8623,
    2.863, 2.8641, 2.8651, 2.8651, 2.866, 2.8664, 2.867, 2.8679, 2.868, 2.8687,
    2.869, 2.8696, 2.8708, 2.8714, 2.8721, 2.8722, 2.8723, 2.8721, 2.8713,
    2.8701, 2.8661, 2.8648, 2.8648, 2.8658, 2.8673, 2.8681, 2.8688, 2.8698,
    2.87, 2.8713, 2.872, 2.8725, 2.8731, 2.8736, 2.8745, 2.8765, 2.8774, 2.8795,
    2.8811, 2.8837, 2.8851, 2.8858, 2.8871, 2.8876, 2.8888, 2.8892, 2.889,
    2.8904, 2.8898, 2.89, 2.8891, 2.8891, 2.8896, 2.889, 2.8899, 2.8892, 2.8905,
    2.8902, 2.8903, 2.8935, 2.893, 2.8948, 2.8949, 2.8941, 2.8938, 2.8982,
    2.8983, 2.8999, 2.9006, 2.901, 2.9046, 2.9031, 2.9052, 2.9079, 2.9093,
    2.9086, 2.9099, 2.9093, 2.9075, 2.9071, 2.9052, 2.9057, 2.9049, 2.905,
    2.9068, 2.9064, 2.9049, 2.9049, 2.906, 2.9077, 2.9083, 2.9122, 2.911,
    2.9119, 2.9119, 2.914, 2.9146, 2.9178, 2.9177, 2.9178, 2.9178, 2.9188,
    2.916, 2.9148, 2.9139, 2.9146, 2.9161, 2.9167, 2.9166, 2.9168, 2.9164,
    2.9153, 2.9153, 2.9169, 2.9196, 2.921, 2.9238, 2.928, 2.9322, 2.9408,
    2.9457, 2.9516, 2.9504, 2.956, 2.9613, 2.9621, 2.9696, 2.9698, 2.9701,
    2.9732, 2.9785, 2.9818, 2.9836, 2.9858, 2.9881, 2.9914, 2.9939, 2.9988,
    3.0018, 3.0051, 3.0128, 3.0219, 3.0275, 3.0362, 3.0445, 3.0517, 3.0543,
    3.0528, 3.0511, 3.0522, 3.0529, 3.0534, 3.0524,
  ],
  Price: [
    9636.97, 9662.86, 9738.6, 9773.03, 9767.01, 9874.9, 9326, 9469.53, 9469.47,
    9345.96, 9431.72, 9524.93, 9463.36, 9399.77, 9312.78, 9360.25, 9298.36,
    9678.68, 9624.68, 9288.06, 9258.67, 9166.49, 9013.91, 9139.9, 9185.17,
    9149.72, 9230.67, 9094.32, 9071.39, 9132.91, 9087.41, 9342.38, 9253.63,
    9432.17, 9235.72, 9282.91, 9234.31, 9297.48, 9240.76, 9247.06, 9203.37,
    9136.48, 9156.28, 9168.4, 9202.62, 9163.16, 9384.38, 9514.3, 9589.82,
    9535.94, 9691.83, 9925.75, 10962.26, 10904.92, 11093.61, 11116.31, 11325.55,
    11812.09, 11066.31, 11230.91, 11181.92, 11719.26, 11768.13, 11571.49,
    11739.13, 11682.85, 11862.94, 11398.67, 11579.87, 11817.16, 11777.39,
    11864.91, 11901.78, 12272.47, 11949.61, 11733.28, 11861.84, 11515.12,
    11676.39, 11647.93, 11758.83, 11350.75, 11465, 11300.4, 11519.12, 11481.48,
    11701, 11672.32, 11895.23, 11418.25, 10197.46, 10484.47, 10177.79, 10260.02,
    10359.45, 10125.01, 10230.15, 10342.16, 10378.22, 10439.38, 10328.87,
    10661.1, 10787.58, 10952.25, 10938, 10927.15, 11084, 10923.33, 10439.52,
    10527.59, 10223.78, 10726.53, 10681.99, 10743.19, 10765.3, 10672.03,
    10837.52, 10770.88, 10627.98, 10572.67, 10546.66, 10670.7, 10784.95,
    10605.96, 10668.01, 10888.67, 11063.25, 11286.54, 11377.81, 11543.18,
    11426.11, 11435.57, 11495.58, 11319.1, 11365.81, 11495.9, 11752.15,
    11908.71, 12806.44, 12951.04, 12928.07, 13106.05, 13021.41, 13060.79,
    13655.19, 13283, 13441.88, 13537.17, 13778.64, 13720.36, 13558.36, 13989.98,
    14101.72, 15553.33, 15548.31, 14818.46, 15496.32, 15335.34, 15278.71,
    15686.91, 16265.15, 16326.81, 16095.56, 15984.67, 16714.4, 17651.82,
    17829.93, 17819.76, 18628.81, 18689.53, 18390.61, 18360.69, 19091.52,
    18753.29, 17138.03, 17140.28, 17718.97, 18169.95, 19609.52, 18857.42,
    19208.4, 19435.2, 18711.23, 19139.11, 19325.55, 19198.81, 18336.51,
    18564.99, 18262.06, 18058.03, 18807.1, 19151.13, 19259.97, 19432.36,
    21317.66, 22805.66, 23120.57, 23863.85, 23518.32, 22840.99, 23794.79,
    23308.13, 23760.29, 24671.11, 26476.13, 26423.23, 27125.38, 27424.54,
    28837.29, 29022.42, 29352.13, 32163.82, 33008.23, 31515.58, 34082.21,
    36933.52, 39547.08, 40815.96, 40296.53, 38397.9, 35669.91, 33938.34,
    37456.01, 39232.75, 36787.52, 36254.71, 35804.26, 36595.46, 36104.86,
    35587.49, 30913.7, 32957.91, 32068.09, 32273.52, 32375.32, 32582.11,
    30445.53, 33128.35, 34150.82, 34199.52, 33064.79, 33405.99, 35485.99,
    37494.72, 36816.51, 38007.83, 39279.41, 38833.34, 46307.57, 46569.56,
    44848.69, 47815.96, 47414.19, 46941.29, 48607.87, 47898.49, 49238.14,
    52143.68, 51733.08, 56038.73, 56377.63, 57669.3, 54410.86, 48691.89,
    49849.38, 46992.67, 46551.49, 46653.53, 44970.16, 49787.34, 48532.24,
    50577.46, 48727.45, 49091.81, 49019.37, 51313.09, 52328.54, 54700.27,
    56020.49, 57788.87, 57353.86, 61315.2, 59428.97, 55805.33, 56830.25,
    59014.93, 57922.41, 58243.27, 58376.16, 57573.54, 54370.14, 54584.87,
    52526.87, 51416.91, 55033.1, 55832.42, 55728.1, 57634.93, 58668.63,
    58817.37, 58801.19, 59060.03, 57060.41, 58228.8, 58706.82, 58062.18,
    56134.42, 58065.64, 58152.99, 59979.39, 59988.02, 59911.02, 63576.68,
    62807.12, 63179.77, 61497.3, 60273.87, 56288.95, 55721.17, 56294.73,
    54190.07, 51965.57, 51191.12, 50133.11, 48981.44, 53978.81, 54991.81,
    54810.61, 53596.7, 57828.51, 57812.97, 56600.75, 57200.3, 53464.37, 57432.1,
    56507.76, 57361.57, 58771.88, 58213.93, 55901.67, 56928.97, 50004.76,
    49972.8, 49913.26, 46780.9, 46585.06, 43780.83, 43091.04, 37287.39,
    40437.73, 37339.63, 37520.32, 34977.53, 38642.44, 38210.81, 39406.93,
    38642.9, 35710.22, 34775.08, 35714.75, 37340.68, 36680.07, 37685.72,
    39151.32, 36938.72, 35546.99, 35834.47, 33451.38, 33557.15, 37553.64,
    36903.3, 37289.43, 35666.15, 39147.71, 40624.51, 40378.2, 38321.45,
    38193.62, 35881.11, 35581.64, 35787.08, 31711.94, 32507.74, 33702.62,
    34675.45, 31943.14, 32023.97, 34607.29, 34556.84, 35968.99, 35171.49,
    33677.57, 33950.79, 34729.89, 35394.21, 33928.13, 34149.99, 33932.25,
    32933.58, 33971.3, 33704.54, 34299.94, 33262.65, 32676.38, 32878.5,
    31726.04, 31399.65, 31588.67, 31919.39, 30928.21, 29971.9, 32383.78,
    32409.5, 33455.55, 34213.6, 35456.12, 37281.92, 39076.59, 40031.2, 39977.76,
    41936.26, 41754.17, 39914.83, 39278.77, 38368.35, 39751.58, 40825.38,
    42802.14, 44647.58, 43753.22, 46311, 45639.51, 45652.06, 44495.21, 47717.27,
    47088.85, 47025, 46031.76, 44534.45, 45015.47, 46745.03, 49195.88, 48933.69,
    49250.54, 49518.58, 47940.61, 49002.7, 47229.07, 49083.1, 48936.54,
    48907.27, 47124.25, 47335.42, 48823.06, 49338.78, 49934.53, 50013.03,
    51696.21, 52739.8, 46995.16, 46085.03, 46518.94, 44802.61, 45196.49,
    46195.22, 45139.83, 47135.43, 48186.53, 47879.47, 47228.66, 48266.63,
    47371.04, 42932.95, 40386.62, 43587.22, 44980.9, 42752.17, 42856.86,
    43336.86, 42247.36, 41010.38, 41587.67, 43859.33, 48181.88, 47777.76,
    48282.97, 49260.23, 51668.99, 55415.01, 53894.6, 54010.94, 55125.46,
    54711.87, 57572.51, 56335.04, 57487.99, 57452.43, 61809.02, 61165.64,
    61583.77, 62017.97, 64517.65, 66237.52, 62541.09, 61029.27, 61572.14,
    61173.17, 63228.21, 60604.19, 58641, 60767.56, 62283.2, 61837.26, 61471.87,
    61121.83, 63247.21, 63130.94, 61601.74, 61247.38, 61661.36, 63153.23,
    67617.02, 67145.37, 65061.05, 65005.65, 64432.05, 64660.34, 65648.98,
    63933.84, 60448.56, 60603.66, 56987.32, 58459.26, 60082.91, 59139.78,
    56386.86, 57748.79, 57198.18, 58897.85, 53827.79, 54533.4, 57237.66,
    57848.77, 57011.28, 57169.37, 56508.27, 53785.97, 49161.99, 49268.47,
    50461.58, 50647.66, 50530.46, 47885.87, 47303.56, 49242.3, 50051.08,
    46696.3, 48410.56, 48936.86, 47694.53, 46328.7, 46970.06, 46807.05,
    47117.01, 49145.22, 48755.9, 50900.61, 50888.72, 50663.49, 50852.86,
    50774.07, 47725.15, 46506.99, 47191.87, 46319.65, 47816.08, 47387.21,
    46531.14, 45938.02, 43647.15, 43216.46, 41527.2, 41756.66, 41862.36,
    41869.86, 42777.21, 43981.88, 42608.41, 43120.85, 43226.65, 43119.79,
    42298.34, 42395.46, 41749.55, 40707.68, 36508.8, 35180.44, 36306.41,
    36774.01, 36988.93, 36870.44, 37276.84, 37852.58, 38232.18, 37983.15,
    38555.53, 38835.69, 37000.98, 37101.35, 41673.84, 41493.69, 42475.54,
    43910.93, 44184.45, 44383.89, 43628.14, 42445.39, 42254.74, 42247.84,
    42634.57, 44574.32, 44063.28, 40562.99, 40073.5, 40192.76, 38514.01,
    37059.98, 38337.2, 37372.29, 38363.35, 39316.16, 39090.2, 37803.59, 43225.4,
    44459.59, 43980.71, 42491.98, 39200.3, 39463.15, 38442.99, 38076.49,
    38732.94, 41986.03, 39468.35, 38775.18, 38903.69, 37852.53, 39669.42,
    39331.85, 41165.64, 41001.71, 41837.41, 42201.94, 41283.26, 41061.82,
    42401.9, 42802.15, 43936.1, 44331.78, 44510.84, 46715.12, 46994.88,
    47459.26, 47063.37, 45528.41, 46270.2, 45842.14, 46434.56, 46622.64,
    45635.45, 43198.78, 43515.15, 42315.71, 42796.4, 42274.91, 39603.97,
    40205.68, 41205.17, 39959.46, 40586.6, 40450.38, 39739.12, 40833.54,
    41498.12, 41397.22, 40528.54, 39756.85, 39561.78, 39469.05, 40488.88,
    38134.22, 39237.95, 39741.77, 38650.55, 37820.61, 38537.65, 38561.57,
    37758.5, 39699.02, 36612.23, 36116.39, 35573.31, 34070.31, 30269.59,
    31026.93, 28913.49, 29126.12, 29310.73, 30189.33, 31319.31, 29923.61,
    30502.19, 28772.2, 30382.41, 29256.81, 29491.51, 30351.05, 29163.17,
    29655.03, 29584.95, 29346.78, 28646.65, 29088.24, 29492.5, 31740.94,
    31865.75, 29833.45, 30481.01, 29714.14, 29872.36, 29917.76, 31372.58,
    31265.47, 30229.24, 30101.19, 29101.3, 28374.14, 26767.27, 22525.77,
    22244.85, 22529.2, 20408.69, 20473.05, 19047.42, 20516.41, 20636.76,
    20700.92, 19983.57, 21100.27, 21262.71, 21525.88, 21052.81, 20751.48,
    20282.54, 20108.53, 19608.4, 19407.45, 19268.23, 19310.23, 20257.01,
    20188.78, 20566.69, 21660.6, 21858.82, 21589.63, 20859.81, 19998.47,
    19350.74, 20225.24, 20574.84, 20794.81, 21193.08, 20824.36, 22395.37,
    23366.91, 23313.3, 23155.21, 22696.9, 22506.2, 22613.72, 21330.3, 21235.61,
    22908.88, 23822.07, 23847.58, 23653.46, 23379.7, 23333.75, 23053.85,
    22860.42, 22678.36, 23225.04, 22984.58, 23197.6, 23823.68, 23203.68,
    23948.83, 23948.75, 24411.38, 24433.65, 24312.54, 24179.01, 23913.33,
    23359.42, 23248.38, 20945.83, 21175.23, 21615.76, 21387.75, 21562.45,
    21395.46, 21618.01, 20271.32, 20069.95, 19659.25, 20308.86, 19805.35,
    20023.55, 20153.98, 19941.45, 19814.56, 19980.01, 19785.73, 18859.5,
    19280.63, 19322.78, 21359.58, 21707.39, 21739.8, 22339.67, 20184.97,
    20255.92, 19702.17, 19764.41, 20131.68, 19437.16, 19570.39, 18869.93,
    18539.64, 19464.32, 19291.9, 18939.97, 18809.41, 19219.73, 19116.33,
    19444.79, 19563.77, 19476.93, 19314.46, 19065.15, 19620.57, 20345.4,
    20161.83, 19949.05, 19457.31, 19418.04, 19448.15, 19142.69, 19058.63,
    19153.04, 19383.94, 19198.11, 19072.78, 19272.92, 19557.9, 19348.03,
    19134.24, 19031.16, 19173.28, 19203.87, 19574.82, 19316.94, 20095.15,
    20774.25, 20278.37, 20591.24, 20800.71, 20623.87, 20494.6, 20489.65,
    20162.98, 20210.91, 21150.32, 21282.99, 20907, 20597.76, 18562.35, 15742.44,
    17594.92, 17080.22, 16797.99, 16344.34, 16645.83, 16890.53, 16689.4,
    16718.36, 16707.02, 16712.73, 16304.08, 15814.34, 16171.63, 16608.01,
    16596.04, 16524.45, 16456.16, 16459.15, 16222.21, 16441.98, 17186.5,
    16972.73, 17084.87, 16923.38, 17149.93, 16973.37, 17085.64, 16851.3,
    17235.01, 17149.82, 17125.36, 17101.04, 17179.6, 17784.16, 17808.72,
    17338.36, 16623.76, 16806.49, 16742.82, 16422.56, 16899.33, 16816.85,
    16824.79, 16791.46, 16848.57, 16842.18, 16900.08, 16701.84, 16538.91,
    16644.38, 16604.02, 16540.69, 16615.97, 16674.34, 16677.65, 16855.17,
    16832.1, 16960.23, 16950.86, 17074.62, 17194.91, 17436.9, 17996.83,
    18866.81, 19941.78, 21019.21, 20853.23, 21175.34, 21156.78, 20726.84,
    21081.67, 22705.83, 22771.02, 22736.66, 22984.87, 22610.01, 23180.42,
    23024.75, 23082.86, 23022.23, 23797.46, 22840.39, 23137.32, 23725.16,
    23539.68, 23451.58, 23340.35, 22946.29, 22786.48, 23294.91, 22947.51,
    21820.89, 21630.13, 21889.43, 21779.87, 21807.83, 22220.07, 24307.88,
    23756.91, 24628.82, 24642.79, 24284.48, 24786.67, 24417.39, 24146.1,
    23951.11, 23172.29, 23157.04, 23541.12, 23518.12, 23155.54, 23634.33,
    23464.34, 22367.43, 22348.33, 22421.89, 22415.11, 22217.21, 21712.65,
    20376.32, 20195.23, 20521.56, 22095.71, 24178.96, 24758.77, 24470.88,
    25161.27, 27466.68, 27107.79, 28186.84, 27929.17, 28264.24, 27449.25,
    28459.74, 27595.49, 27670.2, 28048.47, 27182.3, 27291.35, 28395, 28041.12,
    28516.78, 28498.94, 28236.51, 27815.43, 28175.33, 28197.2, 28042.98,
    27934.44, 27968.13, 28351.24, 29657.97, 30260.94, 29904.14, 30405.03,
    30468.41, 30312.16, 30304.81, 29467.46, 30365.9, 28833.22, 28255.58,
    27300.16, 27861.64, 27606.58, 27511.64, 28351.22, 28352.19, 29483.52,
    29339.99, 29217.94, 29362.06, 28125.5, 28654.39, 28988.32, 28846.46,
    29520.32, 28887.74, 28611.44, 27696.76, 27607.39, 27639.73, 27024.77,
    26787.69, 26798.13, 26911.81, 27227.79, 27022.71, 27389.97, 26842.95,
    26884.37, 27093.79, 26773.83, 26869.69, 27222.94, 26338.95, 26475.61,
    26717.99, 26848.24, 28110.31, 27759.75, 27713.91, 27245.47, 26824.1,
    27247.74, 27066.37, 27315.45, 25792.62, 27216.62, 26346.24, 26507.91,
    26469.58, 25858.12, 25916.58, 25910.36, 25872.21, 25107.75, 25564.6,
    26327.33, 26501.04, 26333.09, 26779.39, 28330.89, 30101.76, 29935.63,
    30629.24, 30537.82, 30454.76, 30285.51, 30693.55, 30083.48, 30466.61,
    30480.78, 30583.62, 30571.89, 31134.71, 30775.62, 30484.79, 29990.06,
    30315.27, 30264.2, 30169.87, 30394.28, 30620.81, 30407.42, 31446.01,
    30311.83, 30297.97, 30237.33, 30147.86, 29848.03, 29919.87, 29787.04,
    29914.68, 29710.16, 30057.96, 29184.9, 29222.97, 29363.67, 29200.24,
    29314.1, 29356.75, 29277.76, 29233.14, 29537.11, 29146.64, 29176.15,
    29087.94, 29046.8, 29044.2, 29178.15, 29779.56, 29585.49, 29423.82,
    29396.85, 29412.14, 29284.97, 29400.59, 29170.49, 28754.2, 26501.59,
    26042.84, 26104.78, 26160.7, 26119.01, 26033.68, 26450.01, 26134.93,
    26044.04, 26002.02, 26082.72, 26109.27, 27730.5, 27297.26, 25927.42,
    25812.33, 25853.66, 25959.6, 25829.36, 25784.41, 25752.96, 26192.33,
    25907.23, 25889.33, 25834.58, 25133.3, 25866.81, 26223.44, 26531.4,
    26634.63, 26557.77, 26520.99, 26741.46, 27219.3, 27115.85, 26561.13,
    26572.04, 26573.92, 26249.56, 26298.63, 26204.76, 26350.15, 27009.01,
    26917.2, 26969.88, 27967.51, 27615.06, 27439.12, 27792.11, 27435.87,
    27958.2, 27977.54, 27948.1, 27593.78, 27392.25, 26842.19, 26729.14,
    26841.14, 26863.18, 27150.3, 28513.31, 28417.72, 28328.25, 28715.75,
    29677.39, 29920.07, 30019.38, 32953.26, 33846.72, 34471.99, 34174.45,
    33899.09, 34092.63, 34556.24, 34498.7, 34672.29, 35457.45, 34924.06,
    34731.38, 35048.41, 35061.93, 35031.27, 35436.54, 35795.08, 36768.42,
    37344.25, 37122.72, 37067.7, 36549.16, 35545.2, 37903.66, 36201.52,
    36527.76, 36582.37, 37413.99, 37489.3, 35965.36, 37464.83, 37293.32,
    37738.93, 37809.85, 37491.84, 37250.17, 37802.24, 37810.35, 37711.82,
    38688.26, 39481.67, 39960.28, 41974.33, 44105.94, 43788.29, 43270.12,
    44202.18, 43745.49, 43757.96, 41200.96, 41450.82, 42931.76, 43009.6,
    41992.01, 42247.07, 41410.82, 42684.18, 42250.21, 43634.12, 43849.7,
    44003.7, 43752.03, 43034.97, 43638.24, 42516.43, 43418.47, 42600.65,
    42074.71, 42220.61, 42208.2, 44168.68, 44994.67, 42821.56, 44195.58,
    44113.7, 43956.12, 43883.74, 46936.19, 46105.95, 46632.31, 46314.36,
    42893.93, 42848.47, 41800.93, 42587.34, 43148, 42713.86, 41261.39, 41600.94,
    41626.11, 41541.9, 39504.73, 39833.45, 40123.67, 39938.29, 41862.94,
    42119.61, 42026.58, 43267.61, 42892.03, 42583.26, 43069.04, 43170.66,
    42976.85, 42599.36, 42648.46, 43087.79, 44247.42, 45338.27, 47143.24, 47769,
    48189.88, 50050.14, 49732.15, 51790.17, 51958.23, 52166.43, 51684.52,
    52138.49, 51764.31, 52286.79, 51842.76, 51319.5, 50841.08, 51553.1,
    51751.88, 54478.19, 57003.53, 62558.58, 61298.22, 62426.64, 62067.87,
    63053.11, 68186.65, 64291.54, 66145.92, 66944.88, 68315.25, 68508, 69075.67,
    72130.55, 71467.17, 73097.77, 71420.03, 69497.76, 65292.31, 68425.42,
    67708.94, 62132.52, 67819.06, 65535.98, 63509.04, 64285.8, 67310.98,
    69938.95, 70082.05, 69435.75, 70710.2, 69919.06, 69702.31, 71246.95,
    69785.93, 65440.42, 66123.94, 68542.18, 67978.61, 69000.65, 69401.97,
    71624.18, 69158.64, 70527.89, 70106.66, 67252.11, 64404.83, 65752.66,
    63430.57, 63720.5, 61328.9, 63461.59, 63988.82, 64894.42, 64936.42,
    66841.67, 66406.95, 64279.52, 64486.18, 63802.33, 63517.25, 63030.43,
    63797.68, 60749.47, 58297.57, 59135.17, 62839.34, 63820.45, 64010.01,
    63172.48, 62363.06, 61215.5, 63148.6, 60888.22, 60776.97, 61507.05,
    62878.78, 61569.11, 66220.52, 65260.88, 67053.12, 66912.62, 66252.71,
    71430.3, 70189.84, 69181.2, 67906.47, 68539.92, 69268.45, 68508.83,
    69367.24, 68316.64, 67577.27, 68372.49, 67474.95, 67704.33, 67740.02,
    68808.29, 70600.01, 71184.6, 70759.59, 69325.36, 69315.1, 69654.16,
    69493.18, 67329.15, 68224.52, 66700.32, 66011.08, 66190.49, 66615.54,
    66460.39, 65109.31, 64907.07, 64844.67, 64071.62, 64239.9, 63221.36,
    60363.23, 61770.41, 60749.9, 61562.65, 60317.1, 60864.44, 62734.39,
    62819.82, 62042.57, 60244.68, 57189.17, 56739.86, 58230.48, 55880.38,
    56665.14, 57988.3, 57704.2, 57388.19, 57899.29, 59152.72, 60942.33,
    64835.49, 65162.24, 64136.31, 63959.57, 66689.55, 67206.06, 68088.14,
    67607.71, 65942.42, 65403.54, 65749.96, 67920.3, 68055.41, 68242.25,
    66770.32, 66219.05, 64679.78, 65357.53, 61407.3, 60738.74, 58006.21,
    53956.26, 55959.84, 55099.95, 61859.03, 60912.59, 60887.71, 58804.23,
    59350.07, 60601.22, 58739.19, 57624.12, 58881.93, 59430.79, 58438.29,
    59574.95, 59060.13, 61144.69, 60387.54, 64049.69, 64157.16, 64265.82,
    62922.59, 59526.56, 59015.3, 59351.58, 59156.25, 58960.24, 57357.72,
    59108.73, 57504.54, 57987.85, 56132.4, 53923.36, 54150.94, 54792.41,
    57049.12, 57624.16, 57381.76, 58106.95, 60620.63, 60003.3, 59214.8,
    58211.12, 60317.03, 61440.41, 62966.53, 63128.22, 63403.4, 63582.6,
    63327.03, 64334.14, 63151.9, 65130.77, 65791, 65934.11, 65663.69, 63243.28,
    60872.67, 60655.56, 60727.86, 62103.01, 62091.93, 62811.8, 62287.39,
    62185.23, 60597.15, 60195.18, 62392.34, 63207.77, 62829.53, 66049.99,
    66962.22, 67647.54, 67328.11, 68465.62, 68388.87, 68962.83, 67394.86,
    67351.05, 66683.97, 68214.05, 66585.67, 67018.17, 67938.55, 69845.3,
    72781.11, 72342.62, 70265, 69507.92, 69299.16, 68803.51, 67793.3, 69335.43,
    75620.89, 75987.24, 76550.02, 76630.23, 80466.72, 88637.42, 88264.6,
    90488.1, 87407.51, 90947.98, 90606.45, 89841.47, 90534.62, 92251.65,
    94217.02, 98509.12, 98927.49, 97679.46, 98015.94, 93004.7, 91931.83,
    95981.18, 95661.6, 97453.25, 96513.14, 97311.71, 95833.14, 96031.63,
    98881.47, 97201.5, 99973.85, 99781.83, 101235.37, 97353.95, 96649.71,
    101123.62, 100000.81, 101352.23, 101367.01,
  ],
};
