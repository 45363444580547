import { NavLink, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Box, Typography, Stack } from "@mui/material";
import { useMemo, useState, ReactNode, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import { Telegram, Twitter, Discord, Right } from "components/Svg";

const NavWrapper = styled("div")`
  display: flex;
  gap: 60px;
  ${({ theme }) => ({
    [theme.breakpoints.down("lg")]: {
      display: "none",
    },
  })};
`;

const NavBox = styled("div")`
  display: flex;
  width: max-content;
  ${({ theme }) => ({
    [theme.breakpoints.down("lg")]: {},
  })};
`;
const NavText = styled(Typography)<{ actived?: boolean; isTop: boolean }>`
  position: relative;
  cursor: pointer;
  line-height: 70px;
  color: #b2b2b2;
  background: ${({ actived }) =>
    actived
      ? `var(--Linear, linear-gradient(93deg, #1F7A55 15.72%, #C0C0C4 71.42%, #EAE3C5 89.98%))`
      : "none"};
  background-clip: ${({ actived }) => (actived ? `text` : "none")};
  -webkit-text-fill-color: ${({ actived }) =>
    actived ? `transparent` : "none"};
  &:hover {
    background: var(
      --Linear,
      linear-gradient(93deg, #1f7a55 15.72%, #c0c0c4 71.42%, #eae3c5 89.98%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const MobileNav = styled(Box)`
  display: block;
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  background: rgba(255, 255, 255, 0.02);
  backdrop-filter: blur(22px);
  z-index: 98;
  transform: translateY(0) translateX(0);
  transition: transform 400ms ease 0s;
  overflow-y: scroll;
`;

const MenuBox = styled(Stack)<{ isTop: boolean }>`
  position: absolute;
  top: 25px;
  right: 20px;
  ${({ theme }) => ({
    [theme.breakpoints.up("lg")]: {
      display: "none",
    },
  })};
  .menu {
    display: block;
    cursor: pointer;
    user-select: none;
    .menu-button-line {
      width: 20px;
      height: 3px;
      color: ${({ isTop }) => (isTop ? "#fff" : "#fff")};
      margin-bottom: 4px;
      border-radius: 1px;
      background-color: ${({ isTop }) => (isTop ? "#fff" : "#fff")};
      transition: all 0.5s ease-in-out;
      &.top {
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0deg)
          rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
      }
      &.middle {
        opacity: 1;
      }
      &.last {
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1) rotateX(0deg)
          rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
      }
    }
    &.open {
      .top {
        transform: translate3d(0, 7px, 0) scale3d(1, 1, 1) rotateX(0deg)
          rotateY(0deg) rotateZ(225deg) skew(0deg, 0deg) !important;
      }

      .middle {
        opacity: 0 !important;
      }

      .last {
        transform: translate3d(0, -7px, 0) scale3d(1, 1, 1) rotateX(0deg)
          rotateY(0deg) rotateZ(315deg) skew(0deg, 0deg) !important;
      }
    }
  }
`;

const NavItemBox = styled(Stack)<{ isTop: boolean }>`
  height: 70px;
  position: relative;
  .icon-box {
    display: none;
  }
  .sub-menu {
    display: none;
  }
  &:hover {
    .icon-box {
      display: flex;
    }
    .sub-menu {
      opacity: 1;
      visibility: visible;
      display: flex;
    }
  }
`;

const SubMenuBoxHead = styled(Stack)`
  padding-top: 30px;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translate(-50%);
  display: block;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
`;

const SubMenuCon = styled(Stack)<{ isTop: boolean }>`
  padding: 20px;
  background: ${({ isTop }) =>
    isTop ? "rgba(255, 255, 255, 0.15)" : "rgba(255, 255, 255, 0.9)"};
  box-shadow: 0px 2px 10px 0px rgba(81, 45, 45, 0.2);
  border-radius: 10px;
  position: relative;
  backdrop-filter: blur(20px);
  &::after {
    display: block;
    position: absolute;
    content: "";
    border-style: solid;
    border-width: 0 10px 10px 10px;
    backdrop-filter: blur(20px);
    border-color: ${({ isTop }) =>
      isTop
        ? "transparent transparent rgba(255, 255, 255, 0.15) transparent"
        : "transparent transparent rgba(255, 255, 255, 0.9) transparent"};
    top: -10px;
    left: 50%;
    transform: translate(-50%);
  }
`;

const SubItemBox = styled(Box)<{ isTop: boolean; isComing: boolean }>`
  cursor: pointer;
  .icon-right {
    display: block;
    opacity: 0;
    visibility: hidden;
  }
  svg {
    .fill-color {
      fill: ${({ isTop }) => (isTop ? "#fff" : "#272727")};
    }
  }
  &:hover {
    .icon-right {
      opacity: ${({ isComing }) => (isComing ? "0" : "1")};
      visibility: ${({ isComing }) => (isComing ? "hidden" : "visible")};
      svg {
        .fill-color {
          fill: ${({ isTop }) => (isTop ? "#fff" : "#1F7A55")};
        }
      }
    }
    .sub-name {
      color: ${({ isTop }) => (isTop ? "#fff" : "#1F7A55")};
    }
    .icon-box {
      background: ${({ isTop }) => (isTop ? "#1F7A55" : "#fff")};
    }
    svg {
      .fill-color {
        fill: ${({ isTop }) => (isTop ? "" : "#1F7A55")};
      }
    }
  }
`;

const IconBox = styled(Stack)<{ isTop: boolean }>`
  background: ${({ isTop }) => (isTop ? "rgba(255, 255, 255, 0.15)" : "#fff")};
  width: 26px;
  height: 26px;
  border-radius: 50%;
  padding: 5px;
  margin-right: 10px;
`;

const SubName = styled(Typography)<{ isTop: boolean; isComing: boolean }>`
  color: ${({ isTop }) => (isTop ? "rgba(255, 255, 255, 0.70)" : "#272727")};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  &::after {
    display: ${({ isComing }) => (isComing ? "block" : "none")};
    position: absolute;
    content: "Coming Soon";
    font-size: 8px;
    color: ${({ isTop }) => (isTop ? "#fff" : "#999999")};
    background: ${({ isTop }) =>
      isTop ? "rgba(255, 255, 255, 0.40)" : "rgba(255, 255, 255, 0.40)"};
    border-radius: 21px;
    padding: 3px 5px;
    top: 0px;
    right: -65px;
  }
`;

const SubNameM = styled(Typography)<{ isTop: boolean; isComing: boolean }>`
  position: relative;
  &::after {
    display: ${({ isComing }) => (isComing ? "block" : "none")};
    position: absolute;
    content: "Coming Soon";
    font-size: 8px;
    color: ${({ isTop }) => (isTop ? "#fff" : "#fff")};
    background: ${({ isTop }) =>
      isTop ? "rgba(255, 255, 255, 0.40)" : "rgba(255, 255, 255, 0.40)"};
    border-radius: 21px;
    padding: 3px 5px;
    top: 2px;
    right: -65px;
  }
`;

const SubDesc = styled(Typography)<{ isTop: boolean }>`
  color: ${({ isTop }) => (isTop ? "rgba(255, 255, 255, 0.6)" : "#999")};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const BraAccordion = styled(Accordion)`
  background: none;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-top: none;
  border-radius: 0;
  box-shadow: none;
  &::before {
    background-color: rgba(255, 255, 255, 0);
  }
  &.Mui-expanded {
    margin: 0;
  }
  &:last-of-type {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

const BraAccordionSummary = styled(AccordionSummary)`
  padding: 10px 20px;
  .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(-90deg);
    &.Mui-expanded {
      transform: rotate(-180deg);
    }
  }
`;

const BraAccordionDetails = styled(AccordionDetails)`
  padding: 0px 20px 20px 20px;
`;

const SubLinkBox = styled(Stack)`
  padding: 10px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
`;

interface subMenuItem {
  name: string;
  desc: string;
  path: string;
  icon?: ReactNode;
  isComing?: boolean;
  target?: string;
}

interface NavItem {
  name: string;
  path: string;
  subMenu?: subMenuItem[];
  target?: string;
  isDisabled?: boolean;
}

const NAV: NavItem[] = [
  {
    name: "Rewards",
    path: "https://app.usdx.money/airdrop",
    target: "_black",
  },
  {
    name: "Ecosystem",
    path: "/ecosystem",
  },
  {
    name: "Community",
    path: "/community",
    subMenu: [
      {
        name: "Telegram",
        desc: "",
        icon: <Telegram />,
        path: "https://t.me/USDX_Community",
        target: "_black",
      },
      {
        name: "Telegram News",
        desc: "",
        icon: <Telegram />,
        path: "https://t.me/usdxmoneyNews",
        target: "_black",
      },
      {
        name: "Twitter",
        desc: "",
        icon: <Twitter />,
        path: "https://twitter.com/usdx_money",
        target: "_black",
      },
      {
        name: "Discord",
        desc: "",
        icon: <Discord />,
        path: "https://discord.gg/usdxmoney",
        target: "_black",
      },
    ],
  },
  {
    name: "Docs",
    path: "https://docs.usdx.money",
    target: "_black",
    // isDisabled: true,
  },
];

const Nav = ({ isTop }: { isTop: boolean }) => {
  const location = useLocation();
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const handleClose = () => setIsOpenMenu(false);

  const curAct = useMemo(() => {
    let path = "";
    let pathArr = NAV.map((e) => {
      return e.path;
    });
    if (location.pathname && pathArr.indexOf(location.pathname) > -1) {
      path = location.pathname;
    }
    return path;
  }, [location]);

  const initState = () => {
    setIsOpenMenu(false);
  };

  useEffect(() => {
    window.addEventListener("resize", () => initState());
    return () => {
      window.removeEventListener("resize", () => initState());
    };
  }, []);
  return (
    <NavBox>
      <NavWrapper>
        {NAV.map((d: NavItem, index: number) => (
          <>
            {d?.subMenu ? (
              <>
                <NavItemBox isTop={isTop}>
                  <NavText
                    className={curAct.indexOf(d.path) > -1 ? "active" : ""}
                    key={index}
                    variant="body1"
                    actived={curAct.indexOf(d.path) > -1}
                    isTop={isTop}
                  >
                    {d.name}
                  </NavText>
                  <SubMenuBoxHead className="sub-menu">
                    <SubMenuCon
                      isTop={isTop}
                      width={d.name === "Community" ? 200 : 300}
                    >
                      {d.subMenu.map(
                        (subItem: subMenuItem, subIndex: number) => (
                          <>
                            {subItem.name ? (
                              <NavLink
                                target={subItem?.target || ""}
                                to={subItem.path}
                                key={subIndex}
                              >
                                <SubItemBox
                                  isTop={isTop}
                                  isComing={subItem?.isComing || false}
                                >
                                  <Stack
                                    mt={subIndex > 0 ? 20 : 0}
                                    justifyContent="start"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <IconBox
                                      justifyContent="center"
                                      alignItems="center"
                                      direction="row"
                                      className="icon-box"
                                      isTop={isTop}
                                    >
                                      {subItem.icon}
                                    </IconBox>
                                    <SubName
                                      className="sub-name"
                                      isTop={isTop}
                                      isComing={subItem?.isComing || false}
                                      mr={12}
                                      variant="h3"
                                    >
                                      {subItem.name}
                                    </SubName>
                                    <Box mt={4} className="icon-right">
                                      <Right />
                                    </Box>
                                  </Stack>
                                  {subItem?.desc && (
                                    <Stack
                                      mt={4}
                                      justifyContent="start"
                                      alignItems="center"
                                      direction="row"
                                    >
                                      <Box width={36} mr={10}></Box>
                                      <SubDesc isTop={isTop} variant="body2">
                                        {subItem.desc}
                                      </SubDesc>
                                    </Stack>
                                  )}
                                </SubItemBox>
                              </NavLink>
                            ) : (
                              <>
                                <SubDesc
                                  isTop={isTop}
                                  variant="body2"
                                  key={subIndex}
                                >
                                  {subItem.desc}
                                </SubDesc>
                              </>
                            )}
                          </>
                        )
                      )}
                    </SubMenuCon>
                  </SubMenuBoxHead>
                </NavItemBox>
              </>
            ) : (
              <NavLink to={d.path} target={d?.target || ""}>
                <NavText
                  key={index}
                  variant="body1"
                  actived={curAct.indexOf(d.path) > -1}
                  isTop={isTop}
                >
                  {d.name}
                </NavText>
              </NavLink>
            )}
          </>
        ))}
      </NavWrapper>
      <MenuBox isTop={isTop}>
        <div
          className={isOpenMenu ? "open menu" : "menu"}
          onClick={() => setIsOpenMenu(!isOpenMenu)}
        >
          <div className="menu-button-line top"></div>
          <div className="menu-button-line middle"></div>
          <div className="menu-button-line last"></div>
        </div>
      </MenuBox>
      <Modal
        open={isOpenMenu}
        className="menu-modal"
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MobileNav>
          {NAV.map((d: NavItem, index: number) => (
            <>
              {d?.subMenu ? (
                <>
                  <BraAccordion>
                    <BraAccordionSummary
                      expandIcon={<ExpandMoreIcon style={{ color: "#fff" }} />}
                    >
                      <Typography mr={12} variant="h3">
                        {d.name}
                      </Typography>
                    </BraAccordionSummary>
                    <BraAccordionDetails>
                      {d.subMenu.map(
                        (subItem: subMenuItem, subIndex: number) => (
                          <>
                            {subItem.name ? (
                              <NavLink
                                onClick={() => {
                                  setIsOpenMenu(false);
                                }}
                                to={subItem.path}
                                target={subItem?.target || ""}
                              >
                                <SubItemBox
                                  isTop={isTop}
                                  isComing={subItem?.isComing || false}
                                >
                                  {subIndex > 0 && (
                                    <Box my={20}>
                                      <Divider
                                        style={{
                                          borderColor:
                                            "rgba(255, 255, 255, 0.15)",
                                        }}
                                      />
                                    </Box>
                                  )}
                                  <Stack
                                    justifyContent="start"
                                    alignItems="center"
                                    direction="row"
                                  >
                                    <IconBox
                                      justifyContent="center"
                                      alignItems="center"
                                      direction="row"
                                      isTop={isTop}
                                    >
                                      {subItem.icon}
                                    </IconBox>
                                    <SubNameM
                                      isTop={isTop}
                                      isComing={subItem?.isComing || false}
                                      mr={12}
                                      variant="h3"
                                    >
                                      {subItem.name}
                                    </SubNameM>
                                    <Right />
                                  </Stack>
                                  {subItem?.desc && (
                                    <Stack
                                      mt={4}
                                      justifyContent="start"
                                      alignItems="center"
                                      direction="row"
                                    >
                                      <Box width={36} mr={10}></Box>
                                      <SubDesc isTop={isTop} variant="body2">
                                        {subItem.desc}
                                      </SubDesc>
                                    </Stack>
                                  )}
                                </SubItemBox>
                              </NavLink>
                            ) : (
                              <SubDesc isTop={isTop} variant="body2">
                                {subItem.desc}
                              </SubDesc>
                            )}
                          </>
                        )
                      )}
                    </BraAccordionDetails>
                  </BraAccordion>
                </>
              ) : (
                <>
                  <NavLink
                    onClick={() => {
                      setIsOpenMenu(false);
                    }}
                    to={d.path}
                    target={d?.target || ""}
                  >
                    <SubLinkBox
                      justifyContent="space-between"
                      alignItems="center"
                      direction="row"
                    >
                      <Typography my={12} variant="h3">
                        {d.name}
                      </Typography>
                    </SubLinkBox>
                  </NavLink>
                </>
              )}
            </>
          ))}
        </MobileNav>
      </Modal>
    </NavBox>
  );
};

export default Nav;
