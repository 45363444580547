import { styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import BgColor from "assets/images/bg-color.svg";
import BgDot from "assets/images/bg-dot.png";
import { useEffect, useMemo, useState } from "react";
import { getEcosystemList } from "utils/api";
import { EcosystemItem, EcosystemType } from "modal/types";
import Grid from "@mui/material/Unstable_Grid2";
import { NavLink } from "react-router-dom";
import { IconLink } from "components/Svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import _ from "lodash";

const Containter = styled(Stack)`
  overflow: hidden;
`;

const Con = styled(Stack)`
  height: 100%;
  width: 1200px;
  min-height: 1300px;
  padding: 130px 25px 100px;
  box-sizing: border-box;
  .swiper-pagination {
    bottom: 0;
  }
  .swiper-pagination-bullet {
    background-color: #fff;
  }
  .swiper-pagination-bullet-active {
    background-color: #1f7a55;
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "90px 20px 60px",
    },
  })};
`;

const BgBox = styled(Stack)`
  position: relative;
`;

const ImgBg1 = styled("img")`
  position: absolute;
  top: 5%;
  right: -60%;
  width: 100%;
`;

const ImgBg2 = styled("img")`
  position: absolute;
  top: 17%;
  right: -50%;
  width: 1600px;
`;

const ImgBg3 = styled("img")`
  position: absolute;
  top: 40%;
  left: -40%;
  width: 100%;
`;

const ImgBg4 = styled("img")`
  position: absolute;
  top: 55%;
  left: -40%;
  width: 1600px;
`;

const ImgBg5 = styled("img")`
  position: absolute;
  top: 5%;
  left: -40%;
  width: 100%;
  z-index: 1;
`;

const Header = styled(Typography)`
  color: #fff;
  font-size: 66px;
  font-weight: 700;
  line-height: 86px;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "40px",
    },
  })};
`;

const SubHead = styled(Typography)`
  color: #c6c6c6;
  font-size: 20px;
  font-weight: 100;
  line-height: 38px;
  font-family: KindSansRegular;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      lineHeight: "28px",
    },
  })};
`;

const TypeBox = styled(Stack)`
  margin-top: 60px;
`;

const TypeItem = styled(Stack)<{ actived?: boolean }>`
  padding: 6px 14px;
  border-radius: 48px;
  border: 1px solid #3c3c3c;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
  box-sizing: border-box;
  cursor: pointer;
  &:hover {
    border: 1px solid transparent;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #0e0e0e, #0e0e0e),
      linear-gradient(90deg, #1f7a55 0%, #eae3c5 100%);
    box-sizing: border-box;
  }
  &.active {
    border: 1px solid transparent;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #0e0e0e, #0e0e0e),
      linear-gradient(90deg, #1f7a55 0%, #eae3c5 100%);
    box-sizing: border-box;
  }
`;

const TypeItem2 = styled(Stack)<{ actived?: boolean }>`
  padding: 6px 14px;
  border-radius: 48px;
  border: 1px solid #3c3c3c;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  margin-right: 10px;
  box-sizing: border-box;
`;

const CardBox = styled(Stack)`
  margin-top: 30px;
`;

const PcBox = styled(Stack)`
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {},
  })};
`;

const MBox = styled(Stack)`
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {},
  })};
`;

const CardItem = styled(Stack)`
  border-radius: 20px;
  border: 1px solid #3c3c3c;
  padding: 20px;
  height: 285px;
  background: #1b1b1b;
  &:hover {
    border: 1px solid #5a5a5a;
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {},
  })};
`;

const ImgBox = styled(Stack)`
  width: 60px;
  height: 60px;
  border-radius: 60px;
  border: 1px solid #3c3c3c;
  background: #000;
`;
const ImgCon = styled(Avatar)`
  width: 80%;
  height: auto;
  max-height: 80%;
  border-radius: 0;
  overflow: hidden;
`;
const CardTitle = styled(Typography)`
  color: #fff;
  font-size: 32px;
  font-weight: 400;
  line-height: 40px;
`;

const CardDesc = styled(Typography)`
  color: #c6c6c6;
  font-size: 14px;
  font-family: KindSansRegular;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

const Home = () => {
  const theme = useTheme();
  const downToMd = useMediaQuery(theme.breakpoints.down("md"));
  const [cardData, setCardData] = useState<EcosystemItem[]>([]);
  const [curType, setCurType] = useState(0);
  const [swiperRef, setSwiperRef] = useState<any>(null);
  const typeList = [
    {
      type: 0,
      name: "All",
    },
    {
      type: EcosystemType.Network,
      name: "Network",
    },
    {
      type: EcosystemType.CEX,
      name: "CEX",
    },
    {
      type: EcosystemType.DEX,
      name: "DEX",
    },
    {
      type: EcosystemType.MoneyMarkets,
      name: "Money Markets",
    },
    {
      type: EcosystemType.Resaking,
      name: "Resaking",
    },
    {
      type: EcosystemType.Oracle,
      name: "Oracle",
    },
    // {
    //   type: EcosystemType.Interoperability,
    //   name: "Interoperability",
    // },
    {
      type: EcosystemType.Yield,
      name: "Yield Swap",
    },
    // {
    //   type: EcosystemType.POR,
    //   name: "POR",
    // },
  ];

  const typeMap = {
    1: "Network",
    2: "CEX",
    3: "DEX",
    4: "Money Markets",
    5: "Restaking",
    6: "Oracle",
    7: "Interoperability",
    8: "Yield Swap",
    9: "POR",
  };

  const curCardData: EcosystemItem[] = useMemo<EcosystemItem[]>(() => {
    let arr = cardData;
    if (curType) {
      arr = cardData.filter((item) => item.type === curType);
    }
    return arr;
  }, [cardData, curType]);

  const curMobileCardData: EcosystemItem[][] = useMemo<
    EcosystemItem[][]
  >(() => {
    return _.chunk(curCardData, 5);
  }, [curCardData]);

  const changeType = (type: number) => {
    setCurType(type);
  };
  const init = async () => {
    try {
      let res = await getEcosystemList();
      if (res && res?.code === 1) {
        setCardData(res.result);
      }
    } catch (error) {
      console.log("Login-error", error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const CardItemEl = ({ item }: { item: EcosystemItem }) => {
    return (
      <CardItem
        justifyContent="space-between"
        alignItems="center"
        direction="column"
      >
        <Stack width={"100%"}>
          <Stack
            justifyContent="flex-start"
            alignItems="center"
            direction="row"
          >
            <ImgBox
              justifyContent="center"
              alignItems="center"
              direction="column"
            >
              <ImgCon src={item.icon} alt="" />
            </ImgBox>
            <CardTitle ml={20} className="card-title">
              {item.name}
            </CardTitle>
          </Stack>
          <Stack
            mt={20}
            justifyContent="flex-start"
            alignItems="center"
            direction="row"
          >
            <TypeItem2>{typeMap[item.type]}</TypeItem2>
          </Stack>
          <Stack mt={20}>
            <CardDesc className="card-desc">{item.comment}</CardDesc>
          </Stack>
        </Stack>
        <Stack
          justifyContent="flex-end"
          alignItems="center"
          direction="row"
          width={"100%"}
        >
          <NavLink target="_blank" to={item.url}>
            <Stack
              justifyContent="center"
              className="website-hover"
              alignItems="center"
              direction="row"
            >
              <CardDesc mr={6} className="website-text">
                WEBSITE
              </CardDesc>
              <IconLink></IconLink>
            </Stack>
          </NavLink>
        </Stack>
      </CardItem>
    );
  };

  return (
    <Containter>
      <BgBox>
        <ImgBg1 src={BgColor} alt="" />
        <ImgBg2 src={BgDot} alt="" />
        <ImgBg3 src={BgColor} alt="" />
        <ImgBg4 src={BgDot} alt="" />
        <Stack
          style={{ position: "relative" }}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Con>
            <Stack>
              <Header>Ecosystem</Header>
              <SubHead>
                A comprehensive view of applications and integrations within the
                usdx.money ecosystem.
              </SubHead>
            </Stack>
            <TypeBox
              justifyContent="flex-start"
              alignItems="center"
              direction="row"
              alignContent={"flex-start"}
              flexWrap={"wrap"}
            >
              {typeList.map((item, index) => {
                return (
                  <TypeItem
                    onClick={() => {
                      changeType(item.type);
                    }}
                    mb={10}
                    key={index}
                    className={curType === item.type ? "active" : ""}
                  >
                    {item.name}
                  </TypeItem>
                );
              })}
            </TypeBox>
            <Box mt={30} overflow={"hidden"}>
              {!downToMd ? (
                <PcBox>
                  <Grid container spacing={30}>
                    {curCardData.map((item: EcosystemItem, index: number) => (
                      <Grid key={index} xs={4} md={4}>
                        <CardItemEl item={item}></CardItemEl>
                      </Grid>
                    ))}
                  </Grid>
                </PcBox>
              ) : (
                <MBox>
                  <Swiper
                    onSwiper={setSwiperRef}
                    initialSlide={0}
                    spaceBetween={20}
                    pagination={true}
                    modules={[Pagination]}
                    autoHeight={true}
                    style={{ width: "100%" }}
                  >
                    {curMobileCardData.map(
                      (itemO: EcosystemItem[], indexO: number) => (
                        <SwiperSlide key={indexO}>
                          {itemO.map((item: EcosystemItem, index: number) => (
                            <Box mb={20}>
                              <CardItemEl key={index} item={item}></CardItemEl>
                            </Box>
                          ))}
                        </SwiperSlide>
                      )
                    )}
                  </Swiper>
                </MBox>
              )}
            </Box>
          </Con>
        </Stack>
      </BgBox>
    </Containter>
  );
};

export default Home;
