// import styled from "@emotion/styled";
import { Stack, useTheme, useMediaQuery, Box, Typography } from "@mui/material";
import { Logo, LogoUSDX, Down, IconLink } from "components/Svg";
import { NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";

const LogoWapper = styled(Stack)<{ isTop: boolean }>`
  cursor: pointer;
  position: relative;
  height: 70px;
  ${({ theme }) => ({
    [theme.breakpoints.up("md")]: {
      ".sub-menu": {
        display: "none",
      },
      "&:hover": {
        ".sub-menu": {
          display: "block",
          opacity: 1,
          visibility: "visible",
        },
      },
    },
  })};
  svg {
    .fill-color {
      fill: ${({ isTop }) => (isTop ? "#fff" : "#fff")};
    }
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      svg: {
        // marginTop: "10px",
      },
    },
  })};
`;

const BrandBox = styled(Stack)`
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translate(-30%);
  display: block;
  opacity: 0;
  visibility: hidden;
`;

const BrandCon = styled(Stack)`
  padding: 20px 30px;
  transition: opacity 0.3s ease-in-out;
  border-radius: 20px;
  border: 1px solid #3c3c3c;
  background: #1b1b1b;
  min-width: 160px;
  margin-top: 10px;
  cursor: pointer;
  &:hover {
    p {
      background: var(
        --Linear,
        linear-gradient(93deg, #1f7a55 15.72%, #c0c0c4 71.42%, #eae3c5 89.98%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

const BrandText = styled(Typography)`
  color: #fff;
  font-family: Arboria-Medium;
  font-size: 16px;
  font-weight: 400;
`;

const LogoBox = ({ isTop }: { isTop: boolean }) => {
  const theme = useTheme();
  const downToMd = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <LogoWapper
      isTop={isTop}
      justifyContent="center"
      alignItems="center"
      direction="row"
    >
      {/* <NavLink to={"/"}> */}
      <Stack
        alignContent="center"
        justifyContent={"flex-start"}
        direction="row"
        onClick={() => {
          window.open("/", "_self");
        }}
      >
        <Logo
          width={downToMd ? "20px" : "30px"}
          height={downToMd ? "20px" : "30px"}
        ></Logo>
        <Stack alignItems="center" direction="row" ml={10}>
          <LogoUSDX
            width={downToMd ? "115px" : "178px"}
            height={downToMd ? "15px" : "23px"}
          ></LogoUSDX>
          {!downToMd && (
            <Box ml={12}>
              <Down />
            </Box>
          )}
        </Stack>
      </Stack>
      {/* </NavLink> */}
      <NavLink target={"_blank"} to="https://github.com/Synth-X/usdxBrandkit">
        <BrandBox className="sub-menu">
          <BrandCon
            alignContent="center"
            justifyContent={"space-between"}
            direction="row"
          >
            <BrandText>Brand Assets</BrandText>
            <Box mt={3}>
              <IconLink />
            </Box>
          </BrandCon>
        </BrandBox>
      </NavLink>
    </LogoWapper>
  );
};

export default LogoBox;
