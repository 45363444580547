import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const IconLink: React.FC<IconProps> = ({ width = "15px", height = "15px" }) => {
  return (
    <SvgIcon style={{ width, height }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.11004 2.46054L11.6141 2.46054C11.8905 2.46054 12.1146 2.6846 12.1146 2.96099V7.4651C12.1146 7.7415 11.8905 7.96556 11.6141 7.96556C11.3378 7.96556 11.1137 7.7415 11.1137 7.4651V4.1692L2.95981 12.3231L2.25205 11.6153L10.4059 3.46145L7.11004 3.46145C6.83365 3.46145 6.60958 3.23739 6.60958 2.96099C6.60958 2.6846 6.83365 2.46054 7.11004 2.46054Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default IconLink;
