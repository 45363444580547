import { SvgIcon } from "@mui/material";
import { IconProps } from "../type";

const Down: React.FC<IconProps> = ({ width = "12px", height = "6px" }) => {
  return (
    <SvgIcon style={{ width, height }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="8"
        viewBox="0 0 15 8"
        fill="none"
      >
        <path
          d="M1 1L7.42857 7L13.8571 1"
          stroke="#9E9E9E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default Down;
