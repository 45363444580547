export type PageBaseEntity = {
  size: number;
  current: number;
  pages: number;
  total: number;
};

export type PagesEntity<T> = {
  pages: number;
  total: number;
  size: number;
  current: number;
  records: T;
};

export type PriceRequest = {
  id: string;
  chainId: string;
  chainName: string;
  baseAsset: string;
  quoteAsset: string;
  proxyContractAddress: string;
  aggregatorContractAddress: string;
  heartbeat: string;
  minNumOracles: string;
  maxNumOracles: string;
  assetType: string;
  lastPrice: string;
  lastUpdate: number;
  confidence: string;
  oneHour: string;
  twentyFourHour: string;
  sevenDay: string;
  priceList: number[];
};

export interface Origin {
  [originID: string]: string;
}

// 1-Network 2-CEX 3-DEX 4-MoneyMarkets 5-Resaking 6-Oracle 7-Interoperability 8-Yield 9-POR

export enum EcosystemType {
  Network = 1,
  CEX = 2,
  DEX = 3,
  MoneyMarkets = 4,
  Resaking = 5,
  Oracle = 6,
  Interoperability = 7,
  Yield = 8,
  POR = 9,
}

export interface EcosystemItem {
  id: number;
  type: EcosystemType;
  name: string;
  icon: string;
  comment: string;
  url: string;
}
