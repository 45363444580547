import { styled } from "@mui/material/styles";
import { Stack, Typography, useTheme, useMediaQuery, Box } from "@mui/material";
import { useShow } from "hooks/useShow";
import { Head, SubHead, Desc } from "./Style";
import Grid from "@mui/material/Unstable_Grid2";
import blog1 from "assets/images/blog-1.png";
import blog2 from "assets/images/blog-2.png";
import blog3 from "assets/images/blog-3.png";
import blog4 from "assets/images/blog-4.png";
import blog5 from "assets/images/blog-5.png";
import blog6 from "assets/images/blog-6.png";
import blog7 from "assets/images/blog-7.jpeg";

import iconRight from "assets/images/icon-right.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { useState } from "react";

import "swiper/css";
import "swiper/css/navigation";

const BlogBox = styled(Stack)`
  /* position: relative; */
  z-index: 2;
`;

const Containter = styled(Stack)`
  height: 100%;
  width: 1200px;
  padding: 15px 25px 275px;
  box-sizing: border-box;
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
      padding: "40px 20px 60px",
    },
  })};
`;

const ImgBox = styled(Stack)`
  height: 212px;
  background: #363636;
`;

const ImgCon = styled("img")`
  height: 212px;
  width: 100%;
`;

const SwiperStack = styled(Stack)`
  position: relative;
  width: 1200px;
  align-items: center;
  justify-content: center;
  margin-top: 90px;
  .swiper {
    width: 1200px;
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  ${({ theme }) => ({
    "@media (max-width: 1440px)": {
      "&,.Blogs-Swiper": {
        marginTop: "0",
        width: "1200px",
      },
      ".box-tit": {
        padding: "0",
      },
      ".swiper": {
        margin: "0 auto",
        width: "100%",
        height: "600px",
      },
    },
    [theme.breakpoints.down("md")]: {
      ".swiper": {
        margin: "0 auto",
        width: "90%",
      },
      ".Blogs-Swiper": {
        margin: "0 auto",
        width: "95%",
      },
      ".box-tit": {
        padding: "0 0 0 5%",
        margin: "0 0 20px 0",
        fontSize: "28px",
      },
    },
    [theme.breakpoints.down("lg")]: {
      "&": {
        width: "100%",
      },
      ".swiper": {
        margin: "0 auto",
        width: "100%",
        height: "600px",
      },
    },
  })};
`;

const BlogItem = styled(Stack)`
  height: 517px;
  width: 380px;
  flex-shrink: 0;
  border-radius: 36px;
  border-radius: 45px;
  border: 1px solid #575757;
  background: #0e0e0e;
  box-sizing: border-box;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  &:hover {
    border: 1px solid transparent;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #0e0e0e, #0e0e0e),
      linear-gradient(90deg, #ffffff 0%, #353535 50%, #6f6f6f 100%);
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      "&": {
        width: "100%",
        height: "460px",
      },
    },
    [theme.breakpoints.down("lg")]: {
      "&": {
        width: "100%",
        height: "460px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        width: "95%",
        height: "460px",
      },
    },
  })};
`;

const Title = styled(Typography)`
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const BlogCon = styled(Stack)``;

const BottomBox = styled(Stack)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;
const BottomCon = styled(Stack)`
  padding: 30px;
`;
const DateText = styled(Typography)`
  color: #bebebe;
  font-family: Arboria-Book;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const ArrowButton = styled(Box)`
  top: 0;
  width: 100px !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &::after {
    opacity: 1;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    text-align: center;
    line-height: 66px !important;
    color: #9e9e9e;
    font-size: 24px !important;
    transition: opacity 0.3s ease, transform 0.3s ease; /* 添加过渡效果 */
    transform: scale(0.9); /* 初始状态略小 */
  }
  &:hover {
    &::after {
      opacity: 1;
      transform: scale(1); /* 放大到正常大小 */
    }
  }
  ${({ theme }) => ({
    [theme.breakpoints.down("lg")]: {
      "&": {
        width: "40px !important",
      },
      "&::after": {
        opacity: "1",
        width: "40px",
        height: "40px",
        lineHeight: "40px !important",
        fontSize: "16px !important",
      },
    },
  })};
`;

const ArrowLeft = styled(ArrowButton)`
  left: -100px !important;
  ${({ theme }) => ({
    "@media (max-width: 1440px)": {
      "&": {
        left: "-20px !important",
      },
    },
    [theme.breakpoints.down("lg")]: {
      "&": {
        left: "-20px !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        left: "10px !important",
      },
    },
  })};
`;

const ArrowRight = styled(ArrowButton)`
  right: -100px !important;
  ${({ theme }) => ({
    "@media (max-width: 1440px)": {
      "&": {
        right: "-20px !important",
      },
    },
    [theme.breakpoints.down("lg")]: {
      "&": {
        right: "-20px !important",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        right: "10px !important",
      },
    },
  })};
`;

const ImgRight = styled("img")`
  height: 8px;
  width: 16px;
`;

const Blog = () => {
  const theme = useTheme();
  const upToMd = useMediaQuery(theme.breakpoints.up("md"));
  const { ref, isShow } = useShow();
  const [swiperRef, setSwiperRef] = useState<any>(null);

  const blogData = [
    {
      title: "SSSIP-1: Setting the Stage for the Next Growth Phase",
      date: "January 16, 2025",
      img: blog7,
      link: "https://medium.com/@USDX.money/sssip-1-setting-the-stage-for-the-next-growth-phase-4cdc30d62739",
    },
    {
      title: "usdx.money Community Campaigns Are Live!",
      date: "December 18, 2024",
      img: blog6,
      link: "https://medium.com/@USDX.money/usdx-money-community-campaigns-are-live-627c0c625db2",
    },
    {
      title: "usdx.money Campaign Season: Upgraded Referral Program!",
      date: "December 10, 2024",
      img: blog5,
      link: "https://medium.com/@USDX.money/usdx-money-campaign-season-upgraded-referral-program-b7e71b38f62b",
    },
    {
      title:
        "How USDX Delivers a 28% 4-Year Annual Yield Amid Intense Stablecoin Competition!",
      date: "December 06, 2024",
      img: blog4,
      link: "https://medium.com/@USDX.money/how-usdx-delivers-a-28-4-year-annual-yield-amid-intense-stablecoin-competition-a733f5360da0",
    },
    {
      title: "First Campaign Season GUIDE! ",
      date: "December 3, 2024",
      img: blog3,
      link: "https://medium.com/@USDX.money/first-campaign-season-participation-guide-earn-more-x-points-to-unlock-exciting-airdrop-rewards-1dc00f4312f8",
    },
    {
      title:
        "First Campaign Season is live! Explore the full landscape of the usdx.money X-Points system.",
      date: "December 3, 2024",
      img: blog2,
      link: "https://medium.com/@USDX.money/first-campaign-season-is-live-explore-the-full-landscape-of-the-usdx-money-x-points-system-30befa865722",
    },
    {
      title: "usdx.money Secures $45m New Financing at $275m Valuation.",
      date: "December 2, 2024",
      img: blog1,
      link: "https://decrypt.co/294298/usdx-money-secures-45m-new-financing-at-275m-valuation",
    },
  ];

  return (
    <BlogBox justifyContent="center" alignItems="center" direction="row">
      <Containter>
        <Stack justifyContent="center" alignItems="center" direction="row">
          <Head>Blog</Head>
        </Stack>
        <SwiperStack>
          <Swiper
            onSwiper={setSwiperRef}
            modules={[Autoplay, Navigation]}
            slidesPerView={upToMd ? 3 : 1} /* PC 显示 3 个，移动端显示 1 个 */
            spaceBetween={upToMd ? 10 : 0}
            loop={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            navigation={{
              prevEl: ".swiper-button-prev",
              nextEl: ".swiper-button-next",
            }}
          >
            {blogData.map((item: any, index: number) => (
              <SwiperSlide key={index}>
                <BlogItem>
                  <a href={item?.link} target="_blank">
                    <ImgBox>
                      <ImgCon src={item.img} />
                    </ImgBox>
                    <BlogCon p={30}>
                      <Title>{item.title}</Title>
                      <BottomBox>
                        <BottomCon
                          justifyContent="space-between"
                          alignItems="center"
                          direction="row"
                        >
                          <DateText>{item.date}</DateText>
                          <ImgRight src={iconRight}></ImgRight>
                        </BottomCon>
                      </BottomBox>
                    </BlogCon>
                  </a>
                </BlogItem>
              </SwiperSlide>
            ))}
          </Swiper>
          <ArrowLeft className="swiper-button-prev"></ArrowLeft>
          <ArrowRight className="swiper-button-next"></ArrowRight>
        </SwiperStack>
      </Containter>
    </BlogBox>
  );
};

export default Blog;
